import { lazy } from "react";
import Loadable from "../ui-component/Loadable";


const TermsAndConditionsWebview = Loadable(
  lazy(() => import("../views/pages/webviews/terms-and-conditions"))
);
const AboutUsWebView = Loadable(
  lazy(() => import("../views/pages/webviews/about-us"))
)
const WebviewsRoutes = {
  path: "webviews",
  children: [
    {
      path: "terms-and-conditions",
      element: <TermsAndConditionsWebview />,
    },
    {
      path: "about-us",
      element: <AboutUsWebView />,
    },
  ],
};

export default WebviewsRoutes;
