import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

function DrawerBody({ children }: PropsWithChildren) {
  return (
    <Box
      sx={{
        p: 2,
        overflowY: "auto",
        height: `calc(100vh - 63px - 65px)`,
      }}
    >
      {children}
    </Box>
  );
}

export default DrawerBody;
