import React, { Fragment, useState } from "react";
import {
  Cell,
  TableInstance,
  useExpanded,
  useTable,
  type Row,
} from "react-table";
import { v4 } from "uuid";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Box,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";

import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import moment from "moment";
import CsTypography from "./CsTypography";

interface IColumn {
  Header: string;
  accessor?: string;
  hidden?: boolean;
}

interface IProps {
  data: object[];
  columns: IColumn[];
}

const CustomTable = ({ data, columns }: any): React.ReactElement => {
  const [openRows, setOpenRows] = useState<number[]>([]);
  const theme: any = useTheme();

  const handleRowClick = (rowIndex: number): void => {
    if (openRows.includes(rowIndex) === true) {
      setOpenRows(openRows.filter((row) => row !== rowIndex));
    } else {
      setOpenRows([...openRows, rowIndex]);
    }
  };

  const {
    getTableProps, // table props from react-table. Type: Function
    getTableBodyProps, // table body props from react-table. Type: Function
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns
          .filter((column: IColumn) => column.hidden)
          .map((column: IColumn) => column.accessor),
      },
    },
    useExpanded
  );

  const hasCollapsibleItem = columns.some(
    (column: any) =>
      column.hidden === true &&
      rows.some((row: any) => row.original[column.accessor])
  );

  return (
    <Box>
      <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
        <Table stickyHeader {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={v4()}>
                {hasCollapsibleItem === true && <TableCell key={v4()} />}
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    key={v4()}
                    style={{ width: column.width }}
                  >
                    <Grid sx={{ display: "flex" }}>
                      {/* <CsTypography
                        label={column.render("Header")}
                        variant="subtitle1"
                      /> */}
                      <Typography
                        fontSize="1.4rem"
                        color="#364152"
                        fontWeight={600}
                      >
                        {column.render("Header")}
                      </Typography>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row: any, rowIndex: number) => {
              prepareRow(row);
              const isRowOpen = openRows.includes(rowIndex);
              return (
                <Fragment key={v4()}>
                  <TableRow key={v4()}>
                    {hasCollapsibleItem === true && (
                      <TableCell
                        sx={{
                          background:
                            isRowOpen === true
                              ? theme.palette.primary.main
                              : "",
                        }}
                      >
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            handleRowClick(rowIndex);
                          }}
                          sx={{
                            color:
                              isRowOpen === true
                                ? theme.palette.cp.buttonText
                                : "",
                          }}
                        >
                          {isRowOpen === true ? (
                            <KeyboardArrowUpIcon fontSize="inherit" />
                          ) : (
                            <KeyboardArrowDownIcon fontSize="inherit" />
                          )}
                        </IconButton>
                      </TableCell>
                    )}
                    {row.cells.map((cell: Cell<object, any>) => (
                      <TableCell
                        {...cell.getCellProps()}
                        key={v4()}
                        sx={{
                          background:
                            isRowOpen === true
                              ? theme.palette.primary.main
                              : "",
                          color: isRowOpen === true ? "red" : "",
                        }}
                      >
                        {/* <CsTypography
                          label={cell.render("Cell")}
                          variant="body2"
                          sx={{
                            color:
                              isRowOpen === true
                                ? theme.palette.cp.buttonText
                                : "",
                          }}
                        /> */}
                        {/* <Typography
                          component={"span"}
                          fontSize="1.4rem"
                          color="#212121"
                        > */}
                        {cell.render("Cell")}
                        {/* </Typography> */}
                      </TableCell>
                    ))}
                  </TableRow>
                  {isRowOpen === true && hasCollapsibleItem === true && (
                    <TableRow>
                      <TableCell
                        colSpan={
                          visibleColumns.length +
                          (hasCollapsibleItem === true ? 1 : 0)
                        }
                        sx={{
                          background:
                            isRowOpen === true ? theme.palette.grey[50] : "",
                        }}
                      >
                        {/* Content inside the collapsible row */}
                        <Collapse in={true} style={{ display: "block" }}>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <CsTypography
                                    variant="h5"
                                    label="More Details"
                                    sx={{
                                      fontWeight: 600,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                              {columns.map((column: any, columnIndex: any) => {
                                if (column.hidden != null) {
                                  const cellValue =
                                    column.accessor.includes("Time") ||
                                    column.accessor.includes("Date")
                                      ? moment(
                                          row.original[column.accessor]
                                        ).format("YYYY-MM-DD")
                                      : row.original[column.accessor];
                                  return (
                                    <>
                                      <TableRow key={v4()}>
                                        <TableCell>
                                          <CsTypography
                                            label={column.Header}
                                            variant="subtitle1"
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <CsTypography
                                            label={cellValue}
                                            variant="body2"
                                          />
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                }
                                return null;
                              })}
                            </TableBody>
                          </Table>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomTable;
