import React, { ChangeEvent, useState } from "react";
import Autocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";

interface CsMultiSelectProps {
  label: string;
  value: string[];
  options: any[];
  onChange: (event: ChangeEvent<{}>, newOptions: string[]) => void;
  getOptionLabel: (option: any) => string;
}

const CsMultiSelect = ({
  label,
  value,
  options,
  onChange,
  getOptionLabel,
}: CsMultiSelectProps) => {
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    const newValue = event.target.checked ? options.map(getOptionLabel) : [];
    onChange(event, newValue);
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState
  ) => {
    if (option === "Select All") {
      return null; // Don't render the "Select All" option in the dropdown
    }

    return <MenuItem {...props}>{getOptionLabel(option)}</MenuItem>;
  };

  return (
    <div>
      <FormControlLabel
        // sx={{ ml: 43 }}
        control={
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAllChange}
            indeterminate={value.length > 0 && value.length < options.length}
          />
        }
        label="Select All"
      />
      <Autocomplete
        size="small"
        multiple
        onChange={onChange}
        options={options}
        value={value}
        getOptionLabel={getOptionLabel}
        filterSelectedOptions
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField {...params} label={label} />
        )}
        renderOption={renderOption}
        disableCloseOnSelect
        fullWidth
      />
    </div>
  );
};

export default CsMultiSelect;
