/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null } from '../models/FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null';
import type { FAB_ResponseModel_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts } from '../models/FAB_ResponseModel_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts';
import type { FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib } from '../models/FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib';
import type { FAB_RoleManagement_Dtos_CreateRoleDto } from '../models/FAB_RoleManagement_Dtos_CreateRoleDto';
import type { FAB_RoleManagement_Dtos_UpdateRoleDto } from '../models/FAB_RoleManagement_Dtos_UpdateRoleDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleManagementService {

    /**
     * @param requestBody 
     * @returns FAB_ResponseModel_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiAppRoleManagementRole(
requestBody?: FAB_RoleManagement_Dtos_CreateRoleDto,
): CancelablePromise<FAB_ResponseModel_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/app/role-management/role',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static deleteApiAppRoleManagementRole(
id: string,
): CancelablePromise<FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/app/role-management/{id}/role',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns FAB_ResponseModel_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiAppRoleManagementRole(
id: string,
requestBody?: FAB_RoleManagement_Dtos_UpdateRoleDto,
): CancelablePromise<FAB_ResponseModel_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/app/role-management/{id}/role',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param roleName 
     * @param skipCount 
     * @param maxResultCount 
     * @returns FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAppRoleManagementPagedRoleList(
roleName?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/role-management/paged-role-list',
            query: {
                'RoleName': roleName,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns FAB_ResponseModel_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiAppRoleManagementRoleById(
id: string,
): CancelablePromise<FAB_ResponseModel_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/role-management/{id}/role-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAppRoleManagementRoleList(): CancelablePromise<FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_RoleManagement_Dtos_RoleDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/role-management/role-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
