/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography(theme: any) {
  return {
    fontFamily: theme?.customization?.fontFamily,
    h6: {
      fontWeight: 500,
      color: theme.heading,
      fontSize: "1.6rem",
    },
    h5: {
      fontSize: "2rem",
      color: theme.heading,
      fontWeight: 500,
    },
    h4: {
      fontSize: "2.2rem",
      color: theme.heading,
      fontWeight: 600,
    },
    h3: {
      fontSize: "2.6rem",
      color: theme.heading,
      fontWeight: 600,
    },
    h2: {
      fontSize: "3.2rem",
      color: theme.heading,
      fontWeight: 700,
    },
    h1: {
      fontSize: "3.6rem",
      color: theme.heading,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: theme.textDark,
    },
    subtitle2: {
      fontSize: "0.75rem",
      fontWeight: 400,
      color: theme.darkTextSecondary,
    },
    caption: {
      fontSize: "0.75rem",
      color: theme.darkTextSecondary,
      fontWeight: 400,
    },
    body1: {
      fontSize: "1.4rem",
      fontWeight: 400,
      lineHeight: "1.334em",
    },
    body2: {
      fontSize: "1.2rem",
      fontWeight: 400,
      lineHeight: "1.4em",
      // color: theme.darkTextPrimary,
    },
    button: {
      textTransform: "capitalize",
    },
    customInput: {
      marginTop: 1,
      marginBottom: 1,
      "& > label": {
        top: 23,
        left: 0,
        color: theme.grey500,
        '&[data-shrink="false"]': {
          top: 5,
        },
      },
      "& > div > input": {
        padding: "30.5px 14px 11.5px !important",
      },
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
      },
    },
    mainContent: {
      backgroundColor: theme?.layout,
      width: "100%",
      minHeight: "calc(100vh - 64px)",
      flexGrow: 1,
      padding: "20px",
      marginTop: "64px",
      marginRight: "20px",
      borderRadius: `${theme?.customization?.borderRadius}px`,
    },
    menuCaption: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: theme.heading,
      padding: "6px",
      textTransform: "capitalize",
      marginTop: "10px",
    },
    subMenuCaption: {
      fontSize: "0.6875rem",
      fontWeight: 500,
      color: theme.darkTextSecondary,
      textTransform: "capitalize",
    },
    commonAvatar: {
      cursor: "pointer",
      borderRadius: "8px",
    },
    smallAvatar: {
      width: "30px",
      height: "30px",
      fontSize: "1rem",
    },
    mediumAvatar: {
      width: "34px",
      height: "34px",
      fontSize: "1.2rem",
    },
    largeAvatar: {
      width: "160px",
      height: "160px",
      fontSize: "1.5rem",
    },
    boxBackground: {
      background: theme.paper,
      padding: "1rem ",
      paddingBottom: "1.2rem",
      borderRadius: "8px",
      // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      border: `2px solid ${theme.buttonText}`,
    },
    downloadCard: {
      background: theme.layout,
      color: theme.buttonText,
      border: `2px solid ${theme.buttonText}`,
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "2rem",
      // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      borderRadius: "8px",
      transition: "transform 0.3s ease-in-out",
      // "&:hover": {
      //   transform: "translateY(-5px) rotate(0deg) scale(1.01)",
      // },
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    },
  };
}
