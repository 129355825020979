import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../store/reducer";

interface UserState {
  userRole: any;
  permission: any;
  currentUserRole: string;
  isLoaded: boolean; // New flag
}

const initialState: UserState = {
  userRole: {},
  permission: {},
  currentUserRole: "",
  isLoaded: false, // Initially false
};

const userRole = createSlice({
  name: "userRole",
  initialState,
  reducers: {
    setUserRole: (
      state,
      {
        payload: { userRole, permission },
      }: PayloadAction<{ userRole: any; permission: any }>
    ) => {
      state.userRole = userRole;
      state.permission = permission;
      state.currentUserRole = userRole.currentUser.roles[0];
      state.isLoaded = true; // Set to true when user role is set
    },
    clearUserRole: (state) => {
      state.userRole = {};
      state.permission = {};
      state.currentUserRole = "";
      state.isLoaded = false; // Set to false when user role is cleared
    },
  },
});

export const { setUserRole, clearUserRole } = userRole.actions;

export default userRole.reducer;

export const selectUserRole = (state: RootState): any => state?.user;
export const selectPermissions = (state: RootState): any => state?.user?.permission;
export const selectIsUserLoaded = (state: RootState): boolean => state?.user?.isLoaded;