import * as PropTypes from "prop-types";

// material-ui
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// third-party
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import LogoSection from "../LogoSection";
import MenuList from "./MenuList";
// import MenuCard from './MenuCard';
// import { drawerWidth } from 'store/constant';
import { drawerWidth } from "../../../store/constant";
import {
  Sidebar as SidebarComponent,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import Header from "../Header";
import { useState } from "react";
import { IconMenu2 } from "@tabler/icons";
import Logo from "../../../assets/Final.png";
import SmallLogo from "../../../assets/FocalScope App Icon.png";

// ==============================|| SIDEBAR DRAWER ||============================== //
const Sidebar = ({ drawerOpen, drawerToggle, window }: any) => {
  const theme: any = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  // const drawer = (
  //   <>
  //     <Box sx={{ display: { xs: "block", md: "none" } }}>
  //       <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
  //         <LogoSection />
  //       </Box>
  //     </Box>
  //     <BrowserView>
  //       <PerfectScrollbar
  //         component="div"
  //         style={{
  //           height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
  //           // paddingLeft: "16px",
  //           // paddingRight: "16px",
  //         }}
  //       >
  //         <MenuList />
  //         {/* <MenuCard /> */}
  //         {/* <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
  //           <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
  //         </Stack> */}
  //       </PerfectScrollbar>
  //     </BrowserView>
  //     <MobileView>
  //       <Box sx={{ px: 2 }}>
  //         <MenuList />
  //         {/* <MenuCard /> */}
  //         {/* <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
  //           <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
  //         </Stack> */}
  //       </Box>
  //     </MobileView>
  //   </>
  // );

  const container =
    window !== undefined ? () => window.document.body : undefined;
  // const [toggled, setToggled] = useState(false);
  return (
    <SidebarComponent collapsed={drawerOpen}>
      <PerfectScrollbar
        component="div"
        className="ddd"
        // style={{
        //   height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
        //   // paddingLeft: "16px",
        //   // paddingRight: "16px",
        // }}
      >
        <Box
          component="div"
          bgcolor="white"
          px={2}
          py={1.2}
          sx={{ flexGrow: 1 }}
        >
          {drawerOpen ? (
            <img src={SmallLogo} alt="Logo" height="40" />
          ) : (
            <img src={Logo} alt="Logo" height="40"  />
          )}
        </Box>
        <Menu>

          <MenuList />
        </Menu>

      </PerfectScrollbar>
    </SidebarComponent>
    
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
