import { Box, TablePagination } from "@mui/material";
import React from "react";

interface IProps {
  component: React.ElementType;
  count: number;
  page: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  rowPerPage: number;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowPerPageOption: number[];
}

const CustomPagination = (props: IProps) => {
  const {
    component,
    count,
    page,
    onPageChange,
    rowPerPage,
    onRowsPerPageChange,
    rowPerPageOption,
  } = props;
  return (
    <Box sx={{}}>
      <TablePagination
        component={component}
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowPerPage}
        rowsPerPageOptions={rowPerPageOption}
      />
    </Box>
  );
};

export default CustomPagination;
