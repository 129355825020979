import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { BlockListService } from "../../../services/focal-scope-services";

interface BlockDomainPayload {
  BlockValue?: string;
}

const usePostBlockDomain = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const cache = useQueryClient();

  return useMutation({
    mutationFn: async (payload: BlockDomainPayload) => {
      const response = await handleRequest(
        BlockListService.postApiAppBlockListBlockDomain(payload)
      );
      if (!response || !response.success) {
        throw new Error(response?.message?.join(",") || "Unknown error occurred");
      }

      return response;
    },
    onSuccess: async (data) => {
      await cache.invalidateQueries({ queryKey: ["getAllBlockUser"] });
      // Check if there's a success message in the response
      const successMessage = data?.message?.[0] || "Domain has been blocked successfully";
      toast.success(successMessage);
    },
    onError: (error) => {
      toast.error(error.message || "Unable to Block Domain");
    },
  });
};

export default usePostBlockDomain;
