// import { AnyNaptrRecord } from "dns";

interface AppConfig {
  // Define the properties and their types
  defaultPath: string;
  fontFamily: string;
  borderRadius: number;
  basename: string;
}

const config: AppConfig = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: "/",
  defaultPath: "/dashboard/default",
  fontFamily: `'Plus Jakarta Sans Variable', sans-serif`,
  borderRadius: 6,
};

export default config;
