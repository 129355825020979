import {
  type UseMutationResult,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CustomerManagementService,
  type FAB_CustomerManagement_Dtos_UpdateUserDto,
} from "../../services/focal-scope-services";
import { toast } from "react-toastify";

const useUpdateRequest = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: async (payload: any) => {
      const response = await handleRequest(
        CustomerManagementService.putApiAppCustomerManagementUserApproval(
          payload
        )
      );
      if (response?.success !== true) {
        throw new Error(response?.message?.join(","));
      }
      return response;
    },
    onSuccess: async (data) => {
      await cache.invalidateQueries({ queryKey: ["getAllRequestData"] });

      // Check if there's a success message in the response
      const successMessage =
        data?.message?.[0] || "User approved successfully";
      toast.success(successMessage);
    },
    onError: (error) => {
      toast.error(error.message || "User rejected successfully");
    },
  });
};

export default useUpdateRequest;
