import { createTheme } from "@mui/material/styles";

// assets
// import colors from 'assets/scss/_themes-vars.module.scss';
import colors from "../assets/scss/_themes-vars.module.scss";
// project imports
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme: any = (customization: any) => {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: color.headlineCP,
    bodyText: color.paragraphCP,
    layout: color.layoutCP,
    paper: color.mainCP,
    primary: color.primaryCP,
    cardBackground: color.cardBackground,
    secondaryPaper: color.secondaryCP,
    backgroundDefault: color.mainCP,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.headlineCP,
    menuSelectedBack: color.primaryCP,
    menuHoverBack: color.grey100,
    // buttonText: color.buttonTextCP,
    divider: color.headlineCP,
    customization,
  };

  const themeOptions: any = {
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    typography: themeTypography(themeOption),
  };

  const themes = createTheme(themeOptions);
  themes.components = {
    ...componentStyleOverrides(themeOption),
    MuiCollapse: {
      styleOverrides: {
        wrapperInner: {
          display: "flex",
          flexDirection: "column",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // variants: [], // Add the missing 'variants' property here
          // position: 'string',
          // fontSize: 'string',
          // transform: 'string',
          // marginBottom: 'string',
          // color: 'string',
        },
      },
    },
  };

  return themes;
};

export default theme;
