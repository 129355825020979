import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { PermissionManagementService } from "../../services/focal-scope-services";

const useGetAllPermissions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  providerName?: string,
  providerKey?: string
) => {
  const getPermissionData = useQuery({
    queryKey: ["getAllPermissions", providerName, providerKey],
    queryFn: async () => {
      return await handleRequest(
        PermissionManagementService.getApiAppPermissionManagement(
          providerName,
          providerKey
        )
      );
    },
  });

  return getPermissionData;
};

export default useGetAllPermissions;
