import * as PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// material-ui
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import { MENU_OPEN, SET_MENU } from "../../../../../store/actions";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { MenuItem } from "react-pro-sidebar";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //
type RootState = {
  customization: any;
};
const NavItem = ({ item, level }: any) => {
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const customization = useSelector((state: RootState) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon
      strokeWidth={1.5}
      size="2rem"
      style={{ marginTop: "auto", marginBottom: "auto" }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width:
          customization.isOpen.findIndex((id: any) => id === item?.id) > -1
            ? 8
            : 6,
        height:
          customization.isOpen.findIndex((id: any) => id === item?.id) > -1
            ? 8
            : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps: any = {
    component: forwardRef((props, ref: any) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const itemHandler = (id: any) => {
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    // <Tooltip title={item.title} placement="right">
    //  <MenuItem
    //     onClick={() => itemHandler(item.id)}
    //     className="proMenuBtn"
    //     icon={<ListItemIcon sx={{ color: "inherit", my: "auto", minWidth: !item?.icon ? 18 : 36 ,justifyContent:'center'}}>
    //       {itemIcon}
    //     </ListItemIcon>}
    // >

    //     <Typography
    //       variant={
    //         customization.isOpen.findIndex((id: any) => id === item.id) > -1
    //           ? "body2"
    //           : "body2"
    //       }
    //       color="inherit"
    //       fontWeight={500}
    //     >
    //       {item.title}
    //     </Typography>
    //   </MenuItem >
    //   </Tooltip>

    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        // borderRadius: `${customization.borderRadius}px`,
        // mb: 0.5,
        alignItems: "flex-start",
        backgroundColor: level > 1 ? "transparent !important" : "inherit",
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 16}px`,
        transition: "all .4s ease-in-out",
        color: "#fff",
        p: 0,
      }}
      selected={
        customization.isOpen.findIndex((id: any) => id === item.id) > -1
      }
      onClick={() => itemHandler(item.id)}
    >
      {/* <ListItemIcon sx={{ color:"white", my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
        {itemIcon}
      </ListItemIcon> */}
      <Tooltip title={item.title} placement="right">
        <MenuItem
          onClick={() => itemHandler(item.id)}
          className="proMenuBtn"
          icon={
            <ListItemIcon
              sx={{
                color: "white",
                my: "auto",
                minWidth: !item?.icon ? 18 : 36,
                justifyContent: "center",
              }}
            >
              {itemIcon}
            </ListItemIcon>
          }
        >
          <Typography
            variant={
              customization.isOpen.findIndex((id: any) => id === item.id) > -1
                ? "body2"
                : "body2"
            }
            color="inherit"
            fontWeight={500}
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {item.title}
          </Typography>
        </MenuItem>
      </Tooltip>
      {/* <ListItemText
        primary={
          <Typography
            variant={
              customization.isOpen.findIndex((id: any) => id === item.id) > -1
                ? "body2"
                : "body2"
            }
            color="inherit"
            fontWeight={500}
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.subMenuCaption }}
              display="block"
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      /> */}
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;


// import * as PropTypes from "prop-types";
// import { forwardRef, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useLocation } from "react-router-dom";

// // material-ui
// import {
//   Avatar,
//   Chip,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
//   Tooltip,
//   Typography,
//   useMediaQuery,
// } from "@mui/material";
// import { useTheme } from "@mui/material/styles";

// // project imports
// import { MENU_OPEN, SET_MENU } from "../../../../../store/actions";

// // assets
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import { MenuItem } from "react-pro-sidebar";

// // ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

// type RootState = {
//   customization: any;
// };

// const NavItem = ({ item, level }: any) => {
//   const theme: any = useTheme();
//   const dispatch = useDispatch();
//   const { pathname } = useLocation();
//   const customization = useSelector((state: RootState) => state.customization);
//   const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

//   const Icon = item.icon;
//   const itemIcon = item?.icon ? (
//     <Icon
//       strokeWidth={1.5}
//       size="2rem"
//       style={{ marginTop: "auto", marginBottom: "auto" }}
//     />
//   ) : (
//     <FiberManualRecordIcon
//       sx={{
//         width:
//           customization.isOpen.findIndex((id: any) => id === item?.id) > -1
//             ? 8
//             : 6,
//         height:
//           customization.isOpen.findIndex((id: any) => id === item?.id) > -1
//             ? 8
//             : 6,
//       }}
//       fontSize={level > 0 ? "inherit" : "medium"}
//     />
//   );

//   let itemTarget = "_self";
//   if (item.target) {
//     itemTarget = "_blank";
//   }

//   let listItemProps: any = {
//     component: forwardRef((props, ref: any) => (
//       <Link ref={ref} {...props} to={item.url} target={itemTarget} />
//     )),
//   };

//   if (item?.external) {
//     listItemProps = { component: "a", href: item.url, target: itemTarget };
//   }

//   const itemHandler = (id: any) => {
//     dispatch({ type: MENU_OPEN, id });
//     if (matchesSM) dispatch({ type: SET_MENU, opened: false });
//   };

//   // active menu item on page load
//   useEffect(() => {
//     const currentIndex = document.location.pathname
//       .toString()
//       .split("/")
//       .findIndex((id) => id === item.id);
//     if (currentIndex > -1) {
//       dispatch({ type: MENU_OPEN, id: item.id });
//     }
//     // eslint-disable-next-line
//   }, [pathname]);

//   return (
//     <ListItemButton
//       {...listItemProps}
//       disabled={item.disabled}
//       sx={{
//         alignItems: "flex-start",
//         backgroundColor: level > 1 ? "transparent !important" : "inherit",
//         py: level > 1 ? 1 : 1.25,
//         pl: `${level * 16}px`,
//         transition: "all .4s ease-in-out",
//         color: "#fff",
//         p: 0,
//       }}
//       selected={
//         customization.isOpen.findIndex((id: any) => id === item.id) > -1
//       }
//       onClick={() => itemHandler(item.id)}
//     >
//       <Tooltip title={item.title} placement="right">
//         <span>
//           <MenuItem
//             onClick={() => itemHandler(item.id)}
//             className="proMenuBtn"
//             icon={
//               <ListItemIcon
//                 sx={{
//                   color: "white",
//                   my: "auto",
//                   minWidth: !item?.icon ? 18 : 36,
//                   justifyContent: "center",
//                 }}
//               >
//                 {itemIcon}
//               </ListItemIcon>
//             }
//           >
//             <Typography
//               variant="body2"
//               color="inherit"
//               fontWeight={500}
//               textOverflow="ellipsis"
//               overflow="hidden"
//               whiteSpace="nowrap"
//             >
//               {item.title}
//             </Typography>
//           </MenuItem>
//         </span>
//       </Tooltip>
//       {item.chip && (
//         <Chip
//           color={item.chip.color}
//           variant={item.chip.variant}
//           size={item.chip.size}
//           label={item.chip.label}
//           avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
//         />
//       )}
//     </ListItemButton>
//   );
// };

// NavItem.propTypes = {
//   item: PropTypes.object,
//   level: PropTypes.number,
// };

// export default NavItem;
