import { Box, Button, Divider, Stack } from "@mui/material";
import React from "react";

interface ModalFooterComponentType {
  onCloseModal: () => void;
  onEditModal?: () => void;
  handleSubmit?: () => void;
  isEditShow?: boolean;
  icon?: string;
  isLoading?: boolean;
}
function DrawerFooter({
  onCloseModal,
  onEditModal,
  handleSubmit,
  isLoading = false,
  icon,
  isEditShow = true,
}: ModalFooterComponentType) {
  return (
    <>
      <Divider />
      <Box p={2}>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            type="submit"
          >
            {isEditShow ? "Update" : "Add"}
          </Button>
          <Button variant="contained" onClick={onCloseModal} color="error">
            Cancel
          </Button>
        </Stack>
      </Box>
    </>
  );
}

export default DrawerFooter;
