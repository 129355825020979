import { SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  variant:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit"
    | undefined;
  sx?: SxProps;
  label: string | ReactNode;
  onClick?: any;
  component?: React.ElementType; // Add component prop
}

const CsTypography = (props: Props) => {
  const { label, sx, variant, onClick, component: Component = "div" } = props;

  return (
    <Typography
      variant={variant}
      sx={sx}
      onClick={onClick}
      component={Component}
    >
      {label}
    </Typography>
  );
};

export default CsTypography;
