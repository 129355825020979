import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { AbpApplicationConfigurationService } from "../../services/focal-scope-services";

const ApplicationConfigurationService = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const ApplicationConfiguration = useQuery({
    queryKey: ["ApiAbpApplicationConfiguration"],
    queryFn: async () => {
      return await handleRequest(
        AbpApplicationConfigurationService.getApiAbpApplicationConfiguration()
      );
    },
  });

  return ApplicationConfiguration;
};

export default ApplicationConfigurationService;
