import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ConfirmDeleteProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (id: string) => void;
  title: string;
  description: string;
  action?: string;
}

const CsConfirmModal = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  action,
}: ConfirmDeleteProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-delete-dialog"
    >
      <DialogTitle id="confirm-delete-dialog">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm("")}
          color={action === "Approve" || "Confirm" ? "primary" : "error"}
          variant="contained"
          autoFocus
        >
          {action}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CsConfirmModal;
