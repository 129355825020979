import * as PropTypes from "prop-types";

// material-ui
import { Divider, List, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }: any) => {
  const theme: any = useTheme();

  // menu list collapse & items
  const items = item.children?.map((menu: any) => {
    switch (menu?.type) {
      case "collapse":
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case "item":
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      {/* <List
        subheader={
          item.title != null && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.menuCaption, p: 0, m: 0.2 }}
              display="block"
              gutterBottom
            >
              {item.title}
              {item.caption != null && (
                <Typography
                  variant="caption"
                  sx={{ ...theme.typography.subMenuCaption }}
                  display="block"
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      > */}
        {items}
      {/* </List> */}

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25, background: "#0A67B4" }} />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
