import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  PermissionManagementService,
  Volo_Abp_PermissionManagement_UpdatePermissionsDto,
} from "../../services/focal-scope-services";

interface UpdatePermissionsDto {
  providerName?: string;
  providerKey?: string;
  requestBody?: Volo_Abp_PermissionManagement_UpdatePermissionsDto;
}

const useUpdatePermissions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<any, Error, UpdatePermissionsDto, unknown> => {
  const cache = useQueryClient();

  return useMutation({
    mutationFn: async (payload: UpdatePermissionsDto) => {
      const response = await handleRequest(
        PermissionManagementService.putApiAppPermissionManagementAsyncWithResponse(
          payload.providerName,
          payload.providerKey,
          payload.requestBody
        )
      );

      if (response?.success !== true) {
        throw new Error(response?.message?.join(","));
      }

      return response;
    },
    onSuccess: async (data) => {
      await cache.invalidateQueries({ queryKey: ["getAllPermissions"] });
      await cache.invalidateQueries({ queryKey: ["getAllRoleData"] });
      // const successMessage = data?.message?.[0] || "Permission has been updated successfully";
      // toast.success(successMessage);
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.error?.message || "Unable to update Permission ";
      toast.error(errorMessage);
    },
  });
};

export default useUpdatePermissions;
