import authReducer from "../views/utilities/auth/authSlice";
import userReducer from "../views/utilities/auth/userRoleSlice";

import customizationReducer from "./customizationReducer";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import type { PreloadedState } from "@reduxjs/toolkit";
import type { Store } from "redux";

//= =============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
  customization: customizationReducer,
  auth: authReducer,
  user: userReducer,
});

export const setupStore = (
  preloadedState?: PreloadedState<RootState>
): Store<RootState> => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
