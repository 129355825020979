// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "../../../../menu-items";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/reducer";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const userData = useSelector((state: RootState) => state.user);
 
  
  const navItems = menuItem.items
    .map((group: any) => {
      if (group.type === "group" && group.children) {
        const filteredChildren = group.children.filter((item: any) => {
          if (item.permission) {
            // Check if the user has the required permission
            return userData.permission[item.permission];
          }
          // Include the item if it doesn't have a permission property
          return true;
        });

        if (filteredChildren.length > 0) {
          // Construct a new group with filtered children
          const filteredGroup = {
            ...group,
            children: filteredChildren,
          };
          return <NavGroup key={group.id} item={filteredGroup} />;
        }
      }
      return null;
    })
    .filter((item) => item !== null);

  return <>{navItems}</>;
};

export default MenuList;
