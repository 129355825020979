import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { BlockListService } from "../../../services/focal-scope-services";
import { toast } from "react-toastify";

const useUnblockUser = (
    handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
  ) => {
    const cacheData = useQueryClient();
    return useMutation({
      mutationFn: async (id: string) => {
        const response = await handleRequest(
          BlockListService.postApiAppBlockListUnBlock(id)
        );
  
        if (response?.success !== true) {
          throw new Error(response?.message?.join(","));
        }
  
        return response;
      },
      onSuccess: async () => {
        await cacheData.invalidateQueries({ queryKey: ["getAllBlockUser"] });
        toast.success("Unblocked Successfully");
      },
      onError: (error) => {
        toast.error(error.message || "Unable to Unblock");
      },
    });
  };

export default useUnblockUser;
