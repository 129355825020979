import * as PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// project imports
import NavItem from "../NavItem";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import { RootState } from "../../../../../store/reducer";
import { MenuItem, SubMenu } from "react-pro-sidebar";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }: any) => {
  const theme: any = useTheme();
  const customization = useSelector((state: any) => state.customization);
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => state.user);

  // const [open, setOpen] = useState(false);
  // const [selected, setSelected] = useState(null);

  // const handleClick = () => {
  //   setOpen(!open);
  //   setSelected(!selected ? menu.id : null);
  // };

  // const { pathname } = useLocation();
  // const checkOpenForParent = (child: any, id: any) => {
  //   child.forEach((item: any) => {
  //     if (item.url === pathname) {
  //       setOpen(true);
  //       setSelected(id);
  //     }
  //   });
  // };

  // menu collapse for sub-levels
  // useEffect(() => {
  //   setOpen(false);
  //   setSelected(null);
  //   if (menu.children) {
  //     menu.children.forEach((item: any) => {
  //       if (item.children?.length) {
  //         checkOpenForParent(item.children, menu.id);
  //       }
  //       if (item.url === pathname) {
  //         setSelected(menu.id);
  //         setOpen(true);
  //       }
  //     });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname, menu.children, userData]);

  // menu collapse & item
  const menus = menu.children
    ?.filter((item: any) => userData.permission[item.permission])
    ?.map((item: any) => {
      switch (item.type) {
        case "collapse":
          return <NavItem key={item.id} item={item} level={level + 1} />;
        case "item":
          return <NavItem key={item.id} item={item} level={level + 1} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon
      strokeWidth={1.5}
      size="2rem"
      style={{ marginTop: "auto", marginBottom: "auto" }}
    />
  ) : (
    // <FiberManualRecordIcon
    //   sx={{
    //     width: selected === menu.id ? 8 : 6,
    //     height: selected === menu.id ? 8 : 6,
    //   }}
    //   fontSize={level > 0 ? "inherit" : "medium"}
    //   />
    <Icon
      strokeWidth={1.5}
      size="2rem"
      style={{ marginTop: "auto", marginBottom: "auto" }}
    />
  );

  return (
    <>
      <SubMenu
        className="proSubmenuBtn"
        rootStyles={{
          [".ps-submenu-content"]: {
            backgroundColor: "#0a67b4",
          },
        }}
        label={
          <Typography variant="body2" color="inherit" fontWeight={500}>
            {menu.title}
          </Typography>
        }
        icon={
          <ListItemIcon
            sx={{
              color: "white",
              my: "auto",
              minWidth: !menu.icon ? 18 : 36,
              justifyContent: "center",
            }}
          >
            {menuIcon}
          </ListItemIcon>
        }
      >
        {menus}
      </SubMenu>
      {/* <ListItemButton
        sx={{
          // borderRadius: `${customization.borderRadius}px`,
          // mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 25}px`,
          transition: "all .4s ease-in-out",
          color: "#fff",
        }}
        selected={selected === menu.id}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{ color: "white", my: "auto", minWidth: !menu.icon ? 18 : 36 }}
        >
          {menuIcon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant={
                customization.isOpen.findIndex((id: any) => id === menu.id) > -1
                  ? "body2"
                  : "body2"
              }
              color="inherit"
              fontWeight={500}
              sx={{ my: "auto" }}
            >
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography
                variant="caption"
                sx={{ ...theme.typography.subMenuCaption }}
                display="block"
                gutterBottom
              >
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp
            stroke={1.5}
            size="1rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          />
        ) : (
          <IconChevronDown
            stroke={1.5}
            size="1rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            position: "relative",
            "&:after": {
              content: "''",
              position: "absolute",
              left: "32px",
              top: 0,
              height: "100%",
              width: "1px",
              opacity: 1,
            },
          }}
        >
          {menus}
        </List>
      </Collapse> */}
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
};

export default NavCollapse;
