import {
  ManageAccounts as ManageAccountsIcon,
  Apartment as ApartmentIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  RequestPage as RequestPageIcon,
  Key as KeyIcon,
  Handshake as HandshakeIcon,
  Groups2 as Groups2Icon,
  SystemUpdate,
  Groups,
} from "@mui/icons-material";

import Diversity3Icon from "@mui/icons-material/Diversity3";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import {
  IconUserCircle,
  IconUsers,
  IconCategory,
  IconComponents,
  IconSettings,
  IconBellPlus,
  IconArticle,
  IconUserOff,
  IconMail,
  IconHomeQuestion,
  IconKey,
  IconTemplate,
  IconUserBolt,
} from "@tabler/icons-react";

const icon = {
  ManageAccountsIcon,
  ApartmentIcon,
  AdminPanelSettingsIcon,
  IconUserCircle,
  IconUsers,
  IconCategory,
  IconComponents,
  IconSettings,
  Diversity3Icon,
  TextSnippetIcon,
  RequestPageIcon,
  IconBellPlus,
  IconArticle,
  IconUserOff,
  KeyIcon,
  IconMail,
  HandshakeIcon,
  IconHomeQuestion,
  IconKey,
  IconTemplate,
  IconUserBolt,
  SystemUpdate,
  Groups,
};

const pages = {
  id: "pages",
  // title: 'Pages',
  type: "group",
  children: [
    {
      id: "role",
      title: "Role Management",
      type: "item",
      url: "/role-management",
      icon: icon.IconUsers,
      breadcrumbs: false,
      permission: "RoleManagement",
    },
    {
      id: "company",
      title: "Provider Management",
      type: "item",
      url: "/provider-management",
      icon: icon.IconComponents,
      breadcrumbs: false,
      permission: "ProviderManagement",
    },
    {
      id: "users",
      title: "User Management",
      type: "item",
      url: "/application-user-management",
      icon: icon.IconUserCircle,
      breadcrumbs: false,
      permission: "UserManagement",
    },

    {
      id: "customer-management",
      title: "End-user Management",
      type: "item",
      url: "/enduser-management",
      icon: icon.IconUsers,
      breadcrumbs: false,
      permission: "EndUserManagement",
    },
    {
      id: "request-management",
      title: "Request Management",
      type: "item",
      url: "/request-management",
      icon: icon.IconHomeQuestion,
      breadcrumbs: false,
      permission: "EndUserManagement.ManageRequest",
    },
    {
      id: "block-customer",
      title: "Block End-user",
      type: "item",
      url: "/block-enduser",
      icon: icon.IconUserOff,
      breadcrumbs: false,
      permission: "BlockEndUser",
    },
    {
      id: "change-password",
      title: "Change Password",
      type: "item",
      url: "/change-password",
      icon: icon.IconKey,
      breadcrumbs: false,
    },
    {
      id: "total-endusers",
      title: "Total End-users",
      type: "item",
      url: "/total-endusers",
      icon: icon.Groups,
      breadcrumbs: false,
      permission: "TotalEndUserManagement",
    },
    {
      id: "configuration",
      title: "App Settings",
      type: "collapse",
      icon: icon.IconSettings,
      permission: "AppSettingManagement",
      children: [
        {
          id: "about-us",
          title: "About Us",
          type: "item",
          url: "/configuration/about-us",
          icon: icon.IconUserBolt,
          breadcrumbs: false,
          permission: "AboutUs.Edit",
        },
        {
          id: "notification",
          title: "Notification Management",
          type: "item",
          url: "/configuration/notification",
          icon: icon.IconBellPlus,
          breadcrumbs: false,
          permission: "PushNotificationManagement",
        },
        {
          id: "salutation",
          title: "Salutation",
          type: "item",
          url: "/configuration/salutation",
          icon: icon.IconArticle, // or any other appropriate icon
          breadcrumbs: false,
          permission: "AppSettingManagement.CreateSalutation",
        },
        {
          id: "support-email",
          title: "Support Email",
          type: "item",
          url: "/configuration/support-email",
          icon: icon.IconMail, // or any other appropriate icon
          breadcrumbs: false,
          permission: "AppSettingManagement.CreateReportEmail",
        },
        {
          id: "terms-and-condition",
          title: "Terms & Conditions",
          type: "item",
          url: "/configuration/terms-and-condition",
          icon: icon.IconTemplate,
          breadcrumbs: false,
          permission: "TermsAndConditions.Edit",
        },
        {
          id: "app-update",
          title: "Add Update",
          type: "item",
          url: "/configuration/app-update",
          icon: icon.SystemUpdate,
          breadcrumbs: false,
          permission: "AppUpdateManagement",
        },
      ],
    },
  ],
};

export default pages;
