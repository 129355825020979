import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../store/reducer";
import { IDENTITY_CONFIG } from "../../../utils/authConst";

interface AuthState {
  isAuthenticated: boolean | null;
  token: string | null;
  user: any;
}

const authState: AuthState = {
  isAuthenticated: null,
  token: null,
  user: {},
};
const slice = createSlice({
  name: "auth",
  initialState: authState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { isAuthenticated, token, user },
      }: PayloadAction<{
        isAuthenticated: boolean;
        token: string;
        user: object;
      }>
    ) => {
      state.isAuthenticated = isAuthenticated;
      state.token = token;
      state.user = user;
    },
  },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;

export const selectIsAuthenticated = (state: RootState): boolean | null => {
  return state.auth.isAuthenticated;
};
export const selectToken = (): any => {
  const oidcClientData = localStorage.getItem(
    `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
  );
  const jsonData = oidcClientData != null ? JSON.parse(oidcClientData) : null;
  return jsonData?.access_token;
};
export const selectUser = (state: RootState): any => state.auth.user;
