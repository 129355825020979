import { Divider, Drawer, IconButton, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { DrawerHeader } from "../../pages/RoleManagement/AddRole";
import { Close } from "@mui/icons-material";

interface ModalComponentType {
  show: boolean;
  closeModal: any;
  children: any;
  title?: string | ReactNode;
  className?: any;
  showHeader?: boolean;
  size?: "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";
  handleSubmit?: any;
  loading?: boolean;
  width?:number
}
function DrawerContainer({
  show,
  closeModal,
  children,
  title,
  className,
  size,
  loading = false,
  handleSubmit,
  showHeader = true,
  width=500,
  ...rest
}: ModalComponentType) {
  return (
    <Drawer
      open={show}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      anchor="right"
      sx={{
        width: width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: width,
          boxSizing: "border-box",
        },
      }}
    >
      <DrawerHeader>
        <Typography variant="h5">{title}</Typography>
        <IconButton onClick={closeModal}>
          <Close />
        </IconButton>
      </DrawerHeader>
      <Divider />

      {React.cloneElement(children)}
    </Drawer>
  );
}

export default DrawerContainer;
