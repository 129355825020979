import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  RoleManagementService,
  FAB_RoleManagement_Dtos_CreateRoleDto,
} from "../../services/focal-scope-services";

const useAddRole = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const cache = useQueryClient();

  return useMutation({
    mutationFn: async (payload: FAB_RoleManagement_Dtos_CreateRoleDto) => {
      const response = await handleRequest(
        RoleManagementService.postApiAppRoleManagementRole(payload)
      );
      if (response?.success !== true) {
        throw new Error(response?.message?.join(","));
      }

      return response;
    },
    onSuccess: async (data) => {
      await cache.invalidateQueries({ queryKey: ["getAllRoleData"] });
      await cache.invalidateQueries({ queryKey: ["getAllPermissions"] });
      // Check if there's a success message in the response
      const successMessage =
        data?.message?.[0] || "Role has been added successfully";
      toast.success(successMessage);
    },
    onError: (error) => {
      toast.error(error.message || "Unable to add a new Role");
    },
  });
};

export default useAddRole;
