import { Close } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { Box, Stack } from "@mui/system";

import CsTypography from "../../../../ui-component/customComponents/CsTypography";
import DetailLabel from "../../../../ui-component/customComponents/DetailsLabel";
import { DrawerHeader } from "../../RoleManagement/AddRole";
import { FAB_CustomerManagement_Dtos_RequestedUserDto } from "../../../../services/focal-scope-services";
import moment from "moment";

interface RequestDetailsProps {
  open: boolean;
  handleClose: () => void;
  data?: FAB_CustomerManagement_Dtos_RequestedUserDto;
}

const RequestDetails = ({ open, handleClose, data }: RequestDetailsProps) => {

  const formattedDateTime = data?.requestedDate
    ? moment(data.requestedDate).format("DD-MM-YYYY  HH:mm:ss")
    : "";
  return (
    <Drawer
      open={open}
      anchor="right"
      sx={{
        width: 500,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 500,
          boxSizing: "border-box",
        },
      }}
    >
        <ClickAwayListener onClickAway={handleClose}>
        <Box>
      <DrawerHeader>
        <CsTypography label="Request Details" variant="h5" />
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box
        sx={{
          p: 2,
          flex: 1,
        }}
      >
        <Grid container spacing={2}>
          <DetailLabel label="Name" value={data?.fullName ?? ""} />
          <DetailLabel
            label="Contact Info"
            value={`${data?.dialCode ?? ""}-${data?.phoneNo ?? ""}`}
          />
          <DetailLabel label="Provider Name" value={data?.company ?? ""} />
          <DetailLabel label="Designation"  smWidth={12} value={data?.designation ?? ""} />
          <DetailLabel  label="Email" value={data?.email ?? ""} />
          <DetailLabel
            label="Date and Time"
            smWidth={12}
            value={formattedDateTime}
          />
        </Grid>
      </Box>
      </Box>
      </ClickAwayListener>
    </Drawer>
  );
};

export default RequestDetails;
