import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// material-ui
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// project imports
import Breadcrumbs from "../../ui-component/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Customization from '../Customization';
import navigation from "../../menu-items";
import { SET_MENU } from "../../store/actions";
import { drawerWidth } from "../../store/constant";

// assets
import { IconChevronRight } from "@tabler/icons";

// styles

const Main = styled<any>("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    "margin",
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }
  ),
  // [theme.breakpoints.up("md")]: {
  //   marginLeft: open ? 0 : -(drawerWidth - 20),
  //   width: `calc(100% - ${drawerWidth}px)`,
  // },
  // [theme.breakpoints.down("md")]: {
  //   marginLeft: "20px",
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   padding: "16px",
  // },
  // [theme.breakpoints.down("sm")]: {
  //   marginLeft: "10px",
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   padding: "16px",
  //   marginRight: "10px",
  // },
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme: any = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  // Handle left drawer
  const leftDrawerOpened = useSelector(
    (state: any) => state.customization.opened
  );


  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  return (
    <Box sx={{ display: "flex", background: theme.main }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: "white",
          // boxShadow:
          //   "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
          height: 65,
          padding: 1,
          width: (!matchDownMd && !leftDrawerOpened) || (matchDownMd && leftDrawerOpened) ?  "calc(100% - 80px)" :  "calc(100% - 251px)",
        }}
      >
        <Toolbar>
          
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened} sx={{ width: "calc(100vw - 250px)"}}>
        {/* breadcrumb */}
        <Breadcrumbs
          separator={IconChevronRight}
          navigation={navigation}
          icon
          title
          rightAlign
        />
        <Outlet />
      </Main>
      {/* <Customization /> */}
    </Box>
  );
};

export default MainLayout;
