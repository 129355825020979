/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_BlockLists_Dtos_BlockListDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null } from '../models/FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_BlockLists_Dtos_BlockListDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null';
import type { FAB_ResponseModel_1OfOfFAB_BlockLists_Dtos_BlockListDto_FAB_Application_Contracts } from '../models/FAB_ResponseModel_1OfOfFAB_BlockLists_Dtos_BlockListDto_FAB_Application_Contracts';
import type { FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib } from '../models/FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BlockListService {

    /**
     * @param formData 
     * @returns FAB_ResponseModel_1OfOfFAB_BlockLists_Dtos_BlockListDto_FAB_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiAppBlockListBlockDomain(
formData?: {
BlockValue?: string;
},
): CancelablePromise<FAB_ResponseModel_1OfOfFAB_BlockLists_Dtos_BlockListDto_FAB_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/app/block-list/block-domain',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static postApiAppBlockListBlockEmail(
formData?: {
BlockValue?: string;
},
): CancelablePromise<FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/app/block-list/block-email',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param searchKeyword 
     * @returns FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_BlockLists_Dtos_BlockListDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAppBlockListBlockList(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
searchKeyword?: string,
): CancelablePromise<FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_BlockLists_Dtos_BlockListDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/block-list/block-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'SearchKeyword': searchKeyword,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static postApiAppBlockListUnBlock(
id: string,
): CancelablePromise<FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/app/block-list/{id}/un-block',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
