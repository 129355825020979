/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FAB_CustomerManagement_Dtos_UpdateUserCompanyStatus } from '../models/FAB_CustomerManagement_Dtos_UpdateUserCompanyStatus';
import type { FAB_CustomerManagement_Dtos_UpdateUserDto } from '../models/FAB_CustomerManagement_Dtos_UpdateUserDto';
import type { FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_CustomerManagement_Dtos_ApprovedUserDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null } from '../models/FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_CustomerManagement_Dtos_ApprovedUserDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null';
import type { FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_CustomerManagement_Dtos_RequestedUserDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null } from '../models/FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_CustomerManagement_Dtos_RequestedUserDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null';
import type { FAB_ResponseModel_1OfOfFAB_CustomerProfile_Dtos_GetCustomerProfileDto_FAB_Application_Contracts } from '../models/FAB_ResponseModel_1OfOfFAB_CustomerProfile_Dtos_GetCustomerProfileDto_FAB_Application_Contracts';
import type { FAB_ResponseModel_1OfOfSystem_Boolean_System_Private_CoreLib } from '../models/FAB_ResponseModel_1OfOfSystem_Boolean_System_Private_CoreLib';
import type { FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib } from '../models/FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerManagementService {

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param fromDate 
     * @param toDate 
     * @param companyId 
     * @param searchKeyword 
     * @returns FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_CustomerManagement_Dtos_RequestedUserDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAppCustomerManagementRequestedUsersList(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
fromDate?: string,
toDate?: string,
companyId?: string,
searchKeyword?: string,
): CancelablePromise<FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_CustomerManagement_Dtos_RequestedUserDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/customer-management/requested-users-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'FromDate': fromDate,
                'ToDate': toDate,
                'CompanyId': companyId,
                'SearchKeyword': searchKeyword,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns FAB_ResponseModel_1OfOfSystem_Boolean_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static putApiAppCustomerManagementUserApproval(
requestBody?: FAB_CustomerManagement_Dtos_UpdateUserDto,
): CancelablePromise<FAB_ResponseModel_1OfOfSystem_Boolean_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/app/customer-management/user-approval',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param fromDate 
     * @param toDate 
     * @param companyId 
     * @param searchKeyword 
     * @returns FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_CustomerManagement_Dtos_ApprovedUserDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAppCustomerManagementApprovedUsersList(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
fromDate?: string,
toDate?: string,
companyId?: string,
searchKeyword?: string,
): CancelablePromise<FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_CustomerManagement_Dtos_ApprovedUserDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/customer-management/approved-users-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'FromDate': fromDate,
                'ToDate': toDate,
                'CompanyId': companyId,
                'SearchKeyword': searchKeyword,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userId 
     * @returns FAB_ResponseModel_1OfOfFAB_CustomerProfile_Dtos_GetCustomerProfileDto_FAB_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiAppCustomerManagementCustomerDetail(
userId: string,
): CancelablePromise<FAB_ResponseModel_1OfOfFAB_CustomerProfile_Dtos_GetCustomerProfileDto_FAB_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/customer-management/customer-detail/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns FAB_ResponseModel_1OfOfFAB_CustomerProfile_Dtos_GetCustomerProfileDto_FAB_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiAppCustomerManagementCustomerDetail(
requestBody?: FAB_CustomerManagement_Dtos_UpdateUserCompanyStatus,
): CancelablePromise<FAB_ResponseModel_1OfOfFAB_CustomerProfile_Dtos_GetCustomerProfileDto_FAB_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/app/customer-management/customer-detail',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static deleteApiAppCustomerManagement(
id: string,
): CancelablePromise<FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/app/customer-management/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
