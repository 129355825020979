import TextField from "@mui/material/TextField";
import { FormikProps, useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { Button, Divider, Grid, Stack } from "@mui/material";
import { toast } from "react-toastify";

import { styled } from "@mui/material";
import useAddRole from "../../../hooks/RoleManagement/useAddRole";
import useGetAllPermissions from "../../../hooks/RoleManagement/useGetAllPermissions";
import useUpdatePermissions from "../../../hooks/RoleManagement/useUpdatePermissions";
import useUpdateRole from "../../../hooks/RoleManagement/useUpdateRole";
import useFocalScopeCoreApi from "../../../hooks/useFocalScopeAdminApi";
import CsMultiSelect from "../../../ui-component/customComponents/CsMultiSelect";
import DrawerBody from "../../common/drawer/DrawerBody";
import DrawerContainer from "../../common/drawer/DrawerContainer";

import { Box } from "@mui/system";
interface AddRoleProps {
  isEdit: boolean;
  open: boolean;
  onClose: () => void;
  initialData?: any;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}
interface FormValues {
  roleName: string;
}
export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "space-between",
  ...theme.mixins.toolbar,
}));

const AddRole: React.FC<AddRoleProps> = ({
  isEdit,
  open,
  onClose,
  initialData,
  setIsEdit,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const { handleRequest } = useFocalScopeCoreApi();

  const { mutate: addRole, data: responseData } = useAddRole(handleRequest);

  const { mutate: updatePermissions } = useUpdatePermissions(handleRequest);
  const { data, isLoading: specificloading } = useGetAllPermissions(
    handleRequest,
    "R",
    isEdit ? initialData?.name : "admin"
  );

  const { mutate: updateRole } = useUpdateRole(handleRequest);
  const handleSelectChange = (event: any, newValues: string[]) => {
    setSelectedValues(newValues);
  };

  const validationSchema = Yup.object().shape({
    roleName: Yup.string()
      .required("Role Name is required")
      .max(50, "Role Name cannot be longer than 50 characters")
      .matches(/^[a-zA-Z0-9\s]+$/, "Only alphanumeric characters allowed"),
  });

  const formik: FormikProps<FormValues> = useFormik({
    initialValues: {
      roleName: "", // Set initial value based on isEdit
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (isEdit) {
          const updatedRole = {
            roleName: values.roleName,
          };
          await updateRole({
            id: initialData.id,
            payload: updatedRole,
          });

          // Get initially granted permissions
          const initialPermissions =
            data?.groups
              ?.flatMap((group) => group.permissions)
              ?.filter((permission) => permission?.isGranted)
              ?.map((permission) => permission?.name)
              ?.filter((name): name is string => typeof name === "string") ??
            [];

          // Compare initial permissions with updated permissions
          const updatedPermissions = selectedValues.map((permission) => ({
            name: permission,
            isGranted: true,
          }));

          // Find permissions that were initially granted but deselected by the user
          const revokedPermissions = initialPermissions
            .filter(
              (permission) =>
                !updatedPermissions.find((p) => p.name === permission)
            )
            .map((name) => ({
              name,
              isGranted: false,
            }));

          // Update permissions for the new role name
          const permissionsPayload = {
            permissions: [...updatedPermissions, ...revokedPermissions],
          };

          await updatePermissions({
            providerName: "R",
            providerKey: values.roleName,
            requestBody: permissionsPayload,
          });

          // If the role name has changed, remove the old permissions
          if (values.roleName !== initialData.name) {
            await updatePermissions({
              providerName: "R",
              providerKey: initialData.name,
              requestBody: {
                permissions: [],
              },
            });
          }
        } else {
          // If adding a new role
          await addRole({ roleName: values.roleName });

          const permissionsPayload = {
            permissions: selectedValues.map((permission) => ({
              name: permission,
              isGranted: true,
            })),
          };

          await updatePermissions({
            providerName: "R",
            providerKey: values.roleName,
            requestBody: permissionsPayload,
          });
          // if (selectedValues.length > 0) {
          //   toast.success("Permission granted to the role");
          // }
          // else {
          //   toast.error("Permission has not been granted yet");
          // }
        }
        resetForm();
        setSelectedValues([]);
        onClose();
      } catch (error) {
        console.error("Error adding or updating role:", error);
      }
    },
  }) as FormikProps<FormValues>;
  React.useEffect(() => {
    if (isEdit) {
      formik.setValues({
        roleName: initialData?.name || "",
      });

      if (data?.groups) {
        const initialPermissions = data.groups
          .flatMap((group) => group.permissions)
          .filter((permission) => permission?.isGranted)
          .map((permission) => permission?.name)
          .filter((name): name is string => typeof name === "string");
        setSelectedValues(initialPermissions);
      } else {
        setSelectedValues([]);
      }
    } else {
      formik.resetForm(); // Reset form values when not in edit mode
      setSelectedValues([]); // Reset selected permissions when not in edit mode
    }
  }, [isEdit, specificloading, initialData?.roleName, data?.groups]);

  const options =
    data?.groups && data?.groups?.length > 0
      ? data?.groups
          .filter((group: any) =>
            [
              "ProviderManagement",
              "DepartmentManagement",
              "UserManagement",
              "RoleManagement",
              "TermsAndConditions",
              "PermissionManagement",
              "EndUserManagement",
              "PushNotificationManagement",
              "AppSettingManagement",
              "BlockEndUser",
              "AboutUs",
              "AppUpdateManagement",
              "TotalEndUserManagement",
            ].includes(group.name)
          )
          .flatMap((group: any) => {
            const permissions = group.permissions.map(
              (permission: any) => permission.name
            );
            return [...permissions];
          })
      : [];

  const handleclose = () => {
    onClose();
    formik.resetForm();
    setSelectedValues([]);
    setIsEdit(false);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <DrawerContainer
      width={650}
      title={isEdit ? "Edit Role" : "Add New Role"}
      closeModal={handleclose}
      show={open}
      children={
        <form
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DrawerBody>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="roleName"
                  name="roleName"
                  label="Role Name*"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.roleName}
                  onChange={(e) => {
                    formik.setFieldTouched("roleName");
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.roleName && Boolean(formik.errors.roleName)
                  }
                  helperText={
                    formik.touched.roleName &&
                    formik.errors.roleName && <>{formik.errors.roleName}</>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CsMultiSelect
                  label="Select Permissions"
                  value={selectedValues}
                  options={options}
                  onChange={handleSelectChange}
                  getOptionLabel={(option) => option}
                />
              </Grid>
            </Grid>
          </DrawerBody>

          {/* Form Actions */}
          <Divider />
          <Box p={2}>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="contained" color="primary" type="submit">
                {isEdit ? "Update" : "Add"}
              </Button>
              <Button variant="contained" onClick={handleclose} color="error">
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      }
    ></DrawerContainer>
  );
};

export default AddRole;
