import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "../layout/MainLayout";
import Loadable from "../ui-component/Loadable";
import BlockUserList from "../views/pages/Block-User/BlockUserList";
import RequestManagement from "../views/pages/Request-Management/RequestManagement";
import { toast } from "react-toastify";
import { RootState } from "../store/reducer";
import {
  selectIsUserLoaded,
  selectPermissions,
} from "../views/utilities/auth/userRoleSlice";
import TableLoader from "../views/common/loader/TableLoader";

const hasPermission = (permissions: any, requiredPermission: any) => {
  return permissions[requiredPermission];
};

const ProtectedRoute = ({ children, requiredPermission }: any) => {
  const permissions = useSelector(selectPermissions);
  const isUserLoaded = useSelector(selectIsUserLoaded);

  if (!isUserLoaded) {
    return <TableLoader rowNumber={5} colsNumber={7} />;
  }

  if (!hasPermission(permissions, requiredPermission)) {
    return <Navigate to="/not-found" replace />;
  }

  return children;
};

const DashboardDefault = Loadable(lazy(() => import("../views/dashboard")));
const AdminProfile = Loadable(
  lazy(() => import("../views/pages/profile/AdminProfile"))
);
const EditPassword = Loadable(
  lazy(() => import("../views/pages/profile/EditPassword"))
);
const Department = Loadable(
  lazy(() => import("../views/pages/Department/Department"))
);
const UserManagement = Loadable(
  lazy(() => import("../views/pages/User-Management/UserManagement"))
);
const RoleManagement = Loadable(
  lazy(() => import("../views/pages/RoleManagement/RoleManagement"))
);
const AddRole = Loadable(
  lazy(() => import("../views/pages/RoleManagement/AddRole"))
);
const CompanyManagement = Loadable(
  lazy(() => import("../views/pages/Company-Management/CompanyManagement"))
);
const CustomerManagement = Loadable(
  lazy(() => import("../views/pages/CustomerManagement"))
);
const Notification = Loadable(
  lazy(() => import("../views/pages/Configuration/Notifications/Notification"))
);
const SalutationList = Loadable(
  lazy(() => import("../views/pages/Configuration/Salutation/SalutationList"))
);
const SupportEmail = Loadable(
  lazy(() => import("../views/pages/Configuration/SupportEmail"))
);
const TermsAndCondition = Loadable(
  lazy(
    () =>
      import(
        "../views/pages/Configuration/Terms-And-Condition/TermsAndCondition"
      )
  )
);
const AboutUs = Loadable(
  lazy(() => import("../views/pages/Configuration/About-Us/AboutUs"))
);
const UpdateList = Loadable(
  lazy(() => import("../views/pages/Configuration/Add-Update/UpdateList"))
);
const TotalUsers = Loadable(
  lazy(() => import("../views/pages/Total-Endusers/TotalUsers"))
);
const NotFound = Loadable(lazy(() => import("../views/pages/NotFound")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <DashboardDefault />,
    },
    {
      path: "dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "provider-management",
      pathName: "ProviderManagement",
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute requiredPermission="ProviderManagement">
              <CompanyManagement />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "role-management",
      pathName: "RoleManagement",
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute requiredPermission="RoleManagement">
              <RoleManagement />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "enduser-management",
      pathName: "EndUserManagement",
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute requiredPermission="EndUserManagement">
              <CustomerManagement />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "request-management",
      pathName: "EndUserManagement.ManageRequest",
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute requiredPermission="EndUserManagement.ManageRequest">
              <RequestManagement />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "department/:id",
      pathName: "DepartmentManagement",
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute requiredPermission="DepartmentManagement">
              <Department />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "application-user-management",
      pathName: "UserManagement",
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute requiredPermission="UserManagement">
              <UserManagement />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "profile",
      children: [
        {
          index: true,
          element: <AdminProfile />,
        },
      ],
    },
    {
      path: "change-password",
      children: [
        {
          index: true,
          element: <EditPassword />,
        },
      ],
    },
    {
      path: "/block-enduser",
      pathName: "BlockEndUser",
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute requiredPermission="BlockEndUser">
              <BlockUserList />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "total-endusers",
      pathName: "TotalEndUserManagement",
      element: (
        <ProtectedRoute requiredPermission="TotalEndUserManagement">
          <TotalUsers />
        </ProtectedRoute>
      ),
    },
    {
      path: "/configuration",
      children: [
        {
          path: "salutation",
          pathName: "AppSettingManagement",
          element: (
            <ProtectedRoute requiredPermission="AppSettingManagement.CreateSalutation">
              <SalutationList />
            </ProtectedRoute>
          ),
        },
        {
          path: "support-email",
          pathName: "AppSettingManagement.CreateReportEmail",
          element: (
            <ProtectedRoute requiredPermission="AppSettingManagement.CreateReportEmail">
              <SupportEmail />
            </ProtectedRoute>
          ),
        },
        {
          path: "terms-and-condition",
          pathName: "TermsAndConditions.Edit",
          element: (
            <ProtectedRoute requiredPermission="TermsAndConditions.Edit">
              <TermsAndCondition />
            </ProtectedRoute>
          ),
        },
        {
          path: "about-us",
          pathName: "AboutUs.Edit",
          element: (
            <ProtectedRoute requiredPermission="AboutUs.Edit">
              <AboutUs />
            </ProtectedRoute>
          ),
        },
        {
          path: "app-update",
          pathName: "AppUpdateManagement",
          element: (
            <ProtectedRoute requiredPermission="AppUpdateManagement">
              <UpdateList />
            </ProtectedRoute>
          ),
        },

        {
          path: "notification",
          pathName: "PushNotificationManagement",
          element: (
            <ProtectedRoute requiredPermission="PushNotificationManagement">
              <Notification />
            </ProtectedRoute>
          ),
        },
      ],
    },
    { path: "not-found", element: <NotFound /> },
    { path: "*", element: <Navigate to="/not-found" replace /> },
  ],
};

export default MainRoutes;
