import * as PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// ==============================|| NAVIGATION SCROLL TO TOP ||============================== //

interface NavigationScrollProps {
  children?: React.ReactNode;
}

const NavigationScroll: React.FC<NavigationScrollProps> = ({
  children,
}: NavigationScrollProps) => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return children || null;
};

//propsType Validation
NavigationScroll.propTypes = {
  children: PropTypes.node,
};

export default NavigationScroll;
