import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { BlockListService } from "../../../services/focal-scope-services";
const usePostBlockCustomer= (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const cache = useQueryClient();

  return useMutation({
    mutationFn: async (payload: any) => {
      const response = await handleRequest(
        BlockListService.postApiAppBlockListBlockEmail(payload)
      );
      if (response?.success !== true) {
        throw new Error(response?.message?.join(","));
      }

      return response;
    },
    onSuccess: async (data) => {
      await cache.invalidateQueries({ queryKey: ["getAllBlockUser"] });
      // Check if there's a success message in the response
      const successMessage =
        data?.message?.[0] || "End-user has been blocked successfully";
      toast.success(successMessage);
    },
    onError: (error) => {
      toast.error(error.message || "Unable to Block Customer");
    },
  });
};

export default usePostBlockCustomer;
