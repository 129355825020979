import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { CustomerManagementService } from "../../services/focal-scope-services";

const useGetRequest = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  sorting?: string,
  skipCount?: number,
  maxResultCount?: number,
  fromDate?: string,
  toDate?: string,
  companyId?: string,
  searchKeyword?: string
) => {
  const getApprovalRequestData = useQuery({
    queryKey: [
      "getAllRequestData",
      sorting,
      skipCount,
      maxResultCount,
      fromDate,
      toDate,
      companyId,
      searchKeyword,
    ],
    queryFn: async () => {
      return await handleRequest(
        CustomerManagementService.getApiAppCustomerManagementRequestedUsersList(
          sorting,
          skipCount,
          maxResultCount,
          fromDate,
          toDate,
          companyId,
          searchKeyword
        )
      );
    },
  });

  // Log the data here

  return getApprovalRequestData;
};

export default useGetRequest;
