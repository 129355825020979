// import {
//   Paper,
//   Skeleton,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from "@mui/material";

// const TableRowsLoader = ({ rowsNum, colsNum }: any) => {
//   return [...Array(rowsNum)].map((index) => (
//     <TableRow key={index}>
//       {[...Array(colsNum)].map((colIndex) => (
//         <TableCell key={colIndex}>
//           <Skeleton animation="wave" variant="text" />
//         </TableCell>
//       ))}
//     </TableRow>
//   ));
// };

// function TableLoader({ rowNumber, colsNumber }: any) {
//   return (
//     <TableContainer component={Paper}>
//       <Table stickyHeader>
//         <TableHead>
//           <TableRowsLoader rowsNum={1} colsNum={colsNumber} />
//         </TableHead>
//         <TableBody>
//           <TableRowsLoader rowsNum={rowNumber} colsNum={colsNumber} />
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

// export default TableLoader;
import React from 'react';
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const TableRowsLoader = ({ rowsNum, colsNum }: { rowsNum: number; colsNum: number; }) => {
  return (
    <>
      {[...Array(rowsNum)].map((_, rowIndex) => (
        <TableRow key={`row-${rowIndex}`}>
          {[...Array(colsNum)].map((_, colIndex) => (
            <TableCell key={`cell-${rowIndex}-${colIndex}`}>
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

function TableLoader({ rowNumber, colsNumber }: { rowNumber: number; colsNumber: number; }) {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRowsLoader rowsNum={1} colsNum={colsNumber} />
        </TableHead>
        <TableBody>
          <TableRowsLoader rowsNum={rowNumber} colsNum={colsNumber} />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableLoader;
