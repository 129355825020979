/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_Users_Dtos_MemberDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null } from '../models/FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_Users_Dtos_MemberDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null';
import type { FAB_ResponseModel_1OfOfFAB_Users_Dtos_GetUserDto_FAB_Application_Contracts } from '../models/FAB_ResponseModel_1OfOfFAB_Users_Dtos_GetUserDto_FAB_Application_Contracts';
import type { FAB_ResponseModel_1OfOfFAB_Users_Dtos_SetPasswordDto_FAB_Application_Contracts } from '../models/FAB_ResponseModel_1OfOfFAB_Users_Dtos_SetPasswordDto_FAB_Application_Contracts';
import type { FAB_ResponseModel_1OfOfFAB_Users_Dtos_UserDto_FAB_Application_Contracts } from '../models/FAB_ResponseModel_1OfOfFAB_Users_Dtos_UserDto_FAB_Application_Contracts';
import type { FAB_ResponseModel_1OfOfSystem_ByteOf_System_Private_CoreLib } from '../models/FAB_ResponseModel_1OfOfSystem_ByteOf_System_Private_CoreLib';
import type { FAB_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfFAB_DropDown_DropDownDto_FAB_Domain_Shared_System_Private_CoreLib } from '../models/FAB_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfFAB_DropDown_DropDownDto_FAB_Domain_Shared_System_Private_CoreLib';
import type { FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib } from '../models/FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib';
import type { FAB_Users_Dtos_SetPasswordDto } from '../models/FAB_Users_Dtos_SetPasswordDto';
import type { FAB_Users_Dtos_UpdateUserTypeDto } from '../models/FAB_Users_Dtos_UpdateUserTypeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserManagementService {

    /**
     * @param formData 
     * @returns FAB_ResponseModel_1OfOfFAB_Users_Dtos_UserDto_FAB_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiAppUserManagement(
formData?: {
CompanyId?: string;
RoleId?: string;
UserName?: string;
UserEmail?: string;
FirstName?: string;
MiddleName?: string;
Surname?: string;
IsFocalscope?: boolean;
ProfilePicture?: Blob;
},
): CancelablePromise<FAB_ResponseModel_1OfOfFAB_Users_Dtos_UserDto_FAB_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/app/user-management',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns FAB_ResponseModel_1OfOfFAB_Users_Dtos_UserDto_FAB_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiAppUserManagement(
formData?: {
UserId?: string;
IsActive?: boolean;
RoleId?: string;
UserName?: string;
FirstName?: string;
MiddleName?: string;
Surname?: string;
ProfilePicture?: Blob;
},
): CancelablePromise<FAB_ResponseModel_1OfOfFAB_Users_Dtos_UserDto_FAB_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/app/user-management',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static deleteApiAppUserManagement(
id: string,
): CancelablePromise<FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/app/user-management/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns FAB_ResponseModel_1OfOfFAB_Users_Dtos_GetUserDto_FAB_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiAppUserManagement(
id: string,
): CancelablePromise<FAB_ResponseModel_1OfOfFAB_Users_Dtos_GetUserDto_FAB_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/user-management/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns FAB_ResponseModel_1OfOfFAB_Users_Dtos_SetPasswordDto_FAB_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiAppUserManagementSetPassword(
requestBody?: FAB_Users_Dtos_SetPasswordDto,
): CancelablePromise<FAB_ResponseModel_1OfOfFAB_Users_Dtos_SetPasswordDto_FAB_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/app/user-management/set-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns FAB_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfFAB_DropDown_DropDownDto_FAB_Domain_Shared_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static getApiAppUserManagementUserTypeList(): CancelablePromise<FAB_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfFAB_DropDown_DropDownDto_FAB_Domain_Shared_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/user-management/user-type-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns FAB_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfFAB_DropDown_DropDownDto_FAB_Domain_Shared_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static getApiAppUserManagementCompanyList(): CancelablePromise<FAB_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfFAB_DropDown_DropDownDto_FAB_Domain_Shared_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/user-management/company-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static putApiAppUserManagementStatus(
id: string,
): CancelablePromise<FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/app/user-management/{id}/status',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static putApiAppUserManagementUserType(
requestBody?: FAB_Users_Dtos_UpdateUserTypeDto,
): CancelablePromise<FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/app/user-management/user-type',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param companyId 
     * @param searchKeyword 
     * @param isActive 
     * @returns FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_Users_Dtos_MemberDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAppUserManagementPagedAndSortedList(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
companyId?: string,
searchKeyword?: string,
isActive?: boolean,
): CancelablePromise<FAB_ResponseModel_1OfOfAbp_PagedResultDto_1OfOfFAB_Users_Dtos_MemberDto_FAB_Application_Contracts_AbpDddAppContracts_Version_8_1_1_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/user-management/paged-and-sorted-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'CompanyId': companyId,
                'SearchKeyword': searchKeyword,
                'IsActive': isActive,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userId 
     * @returns FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static postApiAppUserManagementReSendEmail(
userId: string,
): CancelablePromise<FAB_ResponseModel_1OfOfSystem_Object_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/app/user-management/re-send-email/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns FAB_ResponseModel_1OfOfSystem_ByteOf_System_Private_CoreLib Success
     * @throws ApiError
     */
    public static getApiAppUserManagementProfilePicture(
id: string,
): CancelablePromise<FAB_ResponseModel_1OfOfSystem_ByteOf_System_Private_CoreLib> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/app/user-management/{id}/profile-picture',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
