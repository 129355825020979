import { Tab, Tabs, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Box } from "@mui/system";
import usePostBlockCustomer from "../../../hooks/appSetting/blockUser/usePostBlockCustomer";
import usePostBlockDomain from "../../../hooks/appSetting/blockUser/usePostBlockDomain";
import DrawerBody from "../../common/drawer/DrawerBody";
import DrawerContainer from "../../common/drawer/DrawerContainer";
import DrawerFooter from "../../common/drawer/DrawerFooter";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducer";

interface BlockUserProps {
  open: boolean;
  onClose: () => void;
}

const AddBlockUser = ({ open, onClose }: BlockUserProps): React.ReactElement => {
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const userData = useSelector((state: RootState) => state.user);
  const permission = userData?.permission;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const validationSchema = Yup.object().shape({
    domain: value === 0 ? Yup.string().required("Domain is required") : Yup.string(),
    customer: value === 1 ? Yup.string().email("Invalid email address").required("End-user email is required") : Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      domain: "",
      customer: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);

      try {
        if (value === 0) {
          // Call domain hook here
          await postBlockDomain.mutateAsync({ BlockValue: values.domain }, {
            onSuccess: () => {
              handleclose();
              resetForm({ values: { domain: "", customer: "" } });
            }
          });
        } else if (value === 1) {
          // Call customer hook here
          await postBlockCustomer.mutateAsync({ BlockValue: values.customer }, {
            onSuccess: () => {
              handleclose();
              resetForm({ values: { domain: "", customer: "" } });
            }
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        // Handle error if needed, e.g., displaying an error message
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleclose = () => {
    onClose();
    formik.resetForm();
  };

  // Hooks for domain and customer blocking
  const postBlockDomain = usePostBlockDomain((payload) => payload);
  const postBlockCustomer = usePostBlockCustomer((payload) => payload);

  // Effect to handle initial tab value based on permissions
  useEffect(() => {
    if (permission?.["BlockEndUser.BlockDomain"]) {
      setValue(0);
    } else if (permission?.["BlockEndUser.BlockEmail"]) {
      setValue(1);
    }
  }, [permission]);

  return (
    <DrawerContainer
      title="Block End-user"
      closeModal={handleclose}
      show={open}
      children={
        <form
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DrawerBody>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="notification tabs"
                sx={{ fontSize: "1.1rem" }}
              >
                {permission?.["BlockEndUser.BlockDomain"] && (
                  <Tab label="Domain" value={0} />
                )}
                {permission?.["BlockEndUser.BlockEmail"] && (
                  <Tab label="End-user" value={1} />
                )}
              </Tabs>
            </Box>
            <Box mt={2}>
              {(permission?.["BlockEndUser.BlockDomain"] && value === 0) && (
                <TextField
                  label="Enter a Domain*"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="domain"
                  value={formik.values.domain}
                  onChange={formik.handleChange}
                  error={formik.touched.domain && Boolean(formik.errors.domain)}
                  helperText={formik.touched.domain && formik.errors.domain}
                />
              )}
              {(permission?.["BlockEndUser.BlockEmail"] && value === 1) && (
                <TextField
                  label="Enter a Email*"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="customer"
                  value={formik.values.customer}
                  onChange={formik.handleChange}
                  error={formik.touched.customer && Boolean(formik.errors.customer)}
                  helperText={formik.touched.customer && formik.errors.customer}
                />
              )}
            </Box>
          </DrawerBody>

          {/* Form Actions */}
          <DrawerFooter
            onCloseModal={handleclose}
            isLoading={isLoading}
            isEditShow={isEdit}
          />
        </form>
      }
    ></DrawerContainer>
  );
};

export default AddBlockUser;
