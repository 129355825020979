import { Close } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TableContainer,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Check, Info, X } from "lucide-react";
import moment, { Moment } from "moment";
import React, { Fragment, ReactElement, useMemo, useState } from "react";
import { Row } from "react-table";
import useGetRequest from "../../../hooks/requestManagement/useGetRequest";
import useUpdateRequest from "../../../hooks/requestManagement/useUpdateRequest";
import useFocalScopeCoreApi from "../../../hooks/useFocalScopeAdminApi";
import useGetCompanyList from "../../../hooks/user/useGetCompanyList";
import {
  FAB_CustomerManagement_Dtos_RequestedUserDto,
  FAB_CustomerManagement_Dtos_UpdateUserDto,
} from "../../../services/focal-scope-services";
import CsConfirmModal from "../../../ui-component/customComponents/CsConfirmModal";
import CsDatePicker from "../../../ui-component/customComponents/CsDatePicker";
import CustomPagination from "../../../ui-component/customComponents/CsPagination";
import CustomTable from "../../../ui-component/customComponents/TableContainer";
import TableLoader from "../../common/loader/TableLoader";
import Header from "../../common/pageHeader/Header";
import RequestDetails from "./RequestDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducer";

interface IColumn {
  Header: string | (() => JSX.Element);
  accessor?: string;
  Cell?: ({ row, value }: { row: Row<any>; value: string }) => JSX.Element;
  hidden?: boolean;
}

const RequestManagement = (): ReactElement => {
  const [requestDetails, setRequestDetails] =
    useState<FAB_CustomerManagement_Dtos_RequestedUserDto>();
  const [action, setAction] = useState<"approve" | "reject" | null>(null);
  const userData = useSelector((state: RootState) => state.user);
 
  const { handleRequest, isLoading } = useFocalScopeCoreApi();
  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(null);
  const [skipCount, setSkipCount] = React.useState<number>(0);
  const [count, setCount] = React.useState<number>(10);
  const [maxResultCount, setMaxResultCount] = React.useState<number>(10);
  const [page, setPage] = React.useState(0);

  const [selectedCompany, setSelectedCompany] = useState("");
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const { mutate: approveRequest,isPending } = useUpdateRequest(handleRequest);
  const { data } = useGetRequest(
    handleRequest,
    "",
    skipCount,
    maxResultCount,
    fromDate?.format("YYYY-MM-DD"),
    toDate?.format("YYYY-MM-DD"),
    selectedCompany,
    searchKeyword
  );

  const handleChange = (event: any) => {
    setSelectedCompany(event ? event.target.value : "");
  };

  const putInTableFirstPage = () => {
    setPage(0);
    setSkipCount(0);
  };

  const handleSearchItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
    putInTableFirstPage();
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPage(newPage);
    setSkipCount(newPage * maxResultCount);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setMaxResultCount(+event.target.value);
    putInTableFirstPage();
  };

  const handleConfirmModalOpen = (
    action: "approve" | "reject",
    index: number
  ) => {
    setAction(action);
    setSelectedRowIndex(index);
    setIsConfirmModalOpen(true);
  };

  const theme: any = useTheme();

  const column: IColumn[] = useMemo(() => {
    const filteredColumns: IColumn[] = [
      {
        Header: "S.No",
        Cell: ({ row }: { row: Row }) => {
          return <>{skipCount + row.index + 1}</>;
        },
      },
      {
        Header: "Username",
        accessor: "userName",
        Cell: ({ value }: { value: string }) => (
          <Box component="span" sx={{ fontWeight: 700 }}>
            {value}
          </Box>
        ),
      },
      {
        Header: "Request Sent",
        accessor: "requestedDate",
        // Cell: ({ row }) => (
        //   <p>{moment(row?.original?.requestedDate).format("MM-DD-YYYY")}</p>
        // ),
        Cell: ({ row }) => {
          const date = moment(row?.original?.requestedDate);
          const formattedDate = date.format("DD-MM-YYYY");
          const formattedTime = date.format("HH:mm:ss");
          return (
            <div>
              <span>{formattedDate}</span>
              &nbsp; &nbsp;
              <span>{formattedTime}</span>
            </div>
          );
        },
      },
      { Header: "Provider", accessor: "company" },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }: any) => (
          <Chip
            label={row.original.status}
            color={
              row.original.status === "Rejected"
                ? "error"
                : row.original.status === "Approval Pending"
                  ? "success"
                  : undefined
            }
          />
        ),
      },
      
      {
        Header: "Action",
        accessor: "Actions",
        hidden: !(
          userData.permission["EndUserManagement.ManageRequest"] ||
          userData.permission["EndUserManagement.Edit"] 
         
        ),
        Cell: ({ row }: any) => (
          <div>
            <Tooltip title="Approve">
              <IconButton
              disabled={isPending}
                size="medium"
                sx={{ color: "#408140" }}
                aria-label="approve"
                onClick={() => handleConfirmModalOpen("approve", row.index)}
              >
                <Check size={20} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reject">
              <IconButton
                size="medium"
                color="error"
                aria-label="reject"
                onClick={() => handleConfirmModalOpen("reject", row.index)}
                disabled={row.original.status === "Rejected"}
              >
                <X size={20} />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
      {
        Header: "Details",
        accessor: "Details",
        Cell: ({ row }: any) => {
          const rowData = row?.original as any;
          return (
            <Tooltip title="Details">
              <IconButton
                size="medium"
                sx={{ color: "#408140" }}
                aria-label="details"
                onClick={() => {
                  setRequestDetails(rowData);
                  setIsDetailsModalOpen(true);
                }}
              >
                <Info size={14} />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ];
    return filteredColumns.filter((column) => column !== null) as IColumn[];
  }, [skipCount]);

  const handleCloseDetailsModal = () => setIsDetailsModalOpen(false);

  const { data: options } = useGetCompanyList(handleRequest);

  const companyOptions = options?.data?.map((item) => ({
    id: item.id,
    name: item.value,
  }));

  return (
    <Fragment>
      <Box>
        <RequestDetails
          open={isDetailsModalOpen}
          handleClose={handleCloseDetailsModal}
          data={requestDetails}
        />
      </Box>
      <Header title="Request Management" />
      <div
        style={{
          display: "flex",
          marginTop: "1em",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1em",
        }}
      >
        <Box style={{ display: "flex", gap: "1rem" }}>
          <CsDatePicker
            label="From Date"
            value={fromDate}
            maxDate={toDate ?? moment(Date.now())}
            onChange={(value) => {
              setFromDate(value);
            }}
          />
          <CsDatePicker
            label="To Date"
            value={toDate}
            minDate={fromDate || undefined}
            maxDate={moment(Date.now())}
            onChange={(value) => {
              setToDate(value);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <FormControl size="small">
            <InputLabel id="company-select">Select Provider</InputLabel>
            <Select
              variant="outlined"
              sx={{ minWidth: 200, position: "relative" }}
              name="company-select"
              value={selectedCompany}
              onChange={handleChange}
              label="Select Provider"
              inputProps={{
                placeholder: selectedCompany ? undefined : "Select Provider",
              }}
              endAdornment={
                selectedCompany ? (
                  <Close onClick={() => handleChange("")} sx={{ marginRight: '10px' }} />
                ) : null
              }
              MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
            >
              {companyOptions?.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            InputProps={{
              startAdornment: (
                  <SearchIcon sx={{ fontSize: "22px" }} />
              ),
            }}
            placeholder="Search by Username"
            onChange={handleSearchItem}
            value={searchKeyword}
            size="small"
          />
        </Box>
      </div>
      <Paper>
        <TableContainer>
          {isLoading ? (
            <TableLoader rowNumber={5} colsNumber={7} />
          ) : (
            <>
              {data?.data?.items && data?.data?.items.length > 0 ? (
                <CustomTable columns={column} data={data?.data?.items} />
              ) : (
                <Typography sx={{ textAlign: "center", margin: "5rem" }}>
                    No Data Available
                  </Typography>
              )}
            </>
          )}
        </TableContainer>

        {isLoading ? (
          <Box p={2} display="flex" justifyContent="end">
            <Skeleton width={200} animation="wave" variant="text" />
          </Box>
        ) : (
          data?.data?.items &&
          data?.data?.items?.length > 0 && (
            <CustomPagination
              component="div"
              count={data?.data?.totalCount ?? 0}
              page={page}
              onPageChange={(
                event: React.MouseEvent<HTMLButtonElement> | null,
                newPage: number
              ) => handleChangePage(event, newPage)} // The handleChangePage function is called when the user changes the page.
              rowPerPage={maxResultCount}
              onRowsPerPageChange={handleChangeRowsPerPage} // handleChangeRowsPerPage is called when the user changes the number of rows per page[10,15,20].
              rowPerPageOption={[5, 10, 15, 20]}
            />
          )
        )}
       

      <CsConfirmModal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={(id) => {
          const selectedRow = data?.data?.items?.[selectedRowIndex!];
          
          if (selectedRow) {
            let payload: FAB_CustomerManagement_Dtos_UpdateUserDto = {};
            
            if (action === "approve") {
              payload = {
                userId: selectedRow.userId,
                companyId: selectedRow.companyId, // Replace with the appropriate field name for companyId
                isApprove: true,
                isReject: false,
              };
            } else if (action === "reject") {
              payload = {
                userId: selectedRow.userId,
                companyId: selectedRow.companyId, // Replace with the appropriate field name for companyId
                isApprove: false,
                isReject: true,
              };
            }
            
            if (payload) {
              approveRequest(payload);
            }
          }
          setIsConfirmModalOpen(false);
        }}
        title={action === "approve" ? "Approve Request" : "Reject Request"}
        description={`Are you sure you want to ${action} this request?`}
        action={action === "approve" ? "Approve" : "Reject"}
        />
        </Paper>
    </Fragment>
  );
};
export default RequestManagement;
