import {
  DatePicker,
  DatePickerProps,
  DatePickerSlotProps,
} from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";
import { Close } from "@mui/icons-material";

interface CsDatePickerProps extends DatePickerProps<Moment> {
  label: string;
  slotProps?: DatePickerSlotProps<Moment, false>;
}

const CsDatePicker = ({
  label,
  slotProps,
  ...datePickerProps
}: CsDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        format="DD/MM/YYYY" 
        slotProps={{
          textField: { size: "small" },
          day: { style: { fontSize: "1.2rem" } },
          actionBar: {
            actions: ["clear"],
          },
          ...slotProps,
        }}
        yearsPerRow={3}
        {...datePickerProps}
      />
    </LocalizationProvider>
  );
};

export default CsDatePicker;
