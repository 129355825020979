import { useQuery } from "@tanstack/react-query";
import { UserManagementService } from "../../../services/focal-scope-services";

const useGetProfilePicture = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string
) => {
  const getSpecProfile = useQuery({
    queryKey: ["getSpecificProfile", id],
    enabled: !!id ? true : false,
    queryFn: async () => {
      return await handleRequest(
        UserManagementService.getApiAppUserManagementProfilePicture(id)
      );
    },
  });

  return getSpecProfile;
};

export default useGetProfilePicture;
