import { Box, Button, Tooltip, Typography, styled } from "@mui/material";
import CsTypography from "../../../ui-component/customComponents/CsTypography";

type THeaderProps = {
  title: string;
  subtitle?: string;
  showButton?: boolean;
  role?: Array<string>;
  clickAction?: () => void;
  buttonName?: string;
  buttonIconComponent?: any;
};

export const TopbarStyled = styled(Box)`
  margin-bottom: 2rem;
  padding: 8px 16px;
  min-height: 55px;
  background: linear-gradient(0deg, rgb(255, 255, 255), rgb(255, 255, 255)),
    rgb(245, 246, 247);
  box-shadow: rgb(230, 232, 235) 0px 1px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(211, 219, 218);
  /* flex-direction: column; */
  @media screen and (min-width:426px) {
    flex-direction: row;
  }
`;
const Header = ({
  title,
  subtitle,
  showButton,
  role,
  clickAction,
  buttonName,
  buttonIconComponent,
}: THeaderProps) => {
  return (
    <>
      <TopbarStyled>
        <CsTypography
          variant="h4"
          sx={{ fontWeight: "500", color: "#0a67b4", fontSize: '2rem' }}
          label={title}
        />
        {showButton && (
          <Button
            color="secondary"
            variant="contained"
            onClick={clickAction}
            startIcon={buttonIconComponent ?? buttonIconComponent}
          >
            {buttonName}
          </Button>
        )}
      </TopbarStyled>
    </>
  );
};

export default Header;
