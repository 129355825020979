import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { FAB_RoleManagement_Dtos_UpdateRoleDto, RoleManagementService } from "../../services/focal-scope-services";

interface ParameterTypes {
  id: string;
  payload?: FAB_RoleManagement_Dtos_UpdateRoleDto;
}

// Hook to update a specific role
const useUpdateRole = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, payload }: ParameterTypes) => {
      const response = await handleRequest(
        RoleManagementService.putApiAppRoleManagementRole(id, payload)
      );
      if (response?.success !== true) {
        throw new Error(response?.message?.join(","));
      }
      return response;
    },
    onSuccess: async (data) => {
      await cache.invalidateQueries({ queryKey: ["getAllRoleData"] });
      // await cache.invalidateQueries({ queryKey: ["ApiAbpApplicationConfiguration"] });
      // Check if there's a success message in the response
      const successMessage = data?.message?.[0] || "Role has been updated successfully";
      toast.success(successMessage);
    },
    onError: (error: any) => {
      const errorMessage = error?.message || "Unable to update role";
      toast.error(errorMessage);
    },
  });
};

export default useUpdateRole;


