import ReactDOM from "react-dom/client";
import React from "react";
// third party
import { Provider } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";

// project imports
import App from "./App";

import { store } from "./store";

// style + assets
import { StrictMode } from "react";

// Supports weights 200-800
import "@fontsource-variable/plus-jakarta-sans";

import "./assets/scss/style.scss";
import config from "./config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";


// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
Sentry.init({
  dsn: window?.__RUNTIME_CONFIG__?.VITE_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.httpClientIntegration(),
    
    
  ],
  sendDefaultPii: true,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost",window?.__RUNTIME_CONFIG__?.VITE_BASE_URL],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: Infinity,
      retry: true,
      retryDelay: 300,
    },
  },
});
const FallbackComponent = () => (
  <div>
    <h1>Something went wrong</h1>
    <p>We're working on getting this fixed as soon as we can.</p>
  </div>
);


// ==============================|| REACT DOM RENDER  ||============================== //

// const container = document.getElementById('root');
// createRoot(container!) if you use TypeScript
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter basename={config.basename}>
          {window?.__RUNTIME_CONFIG__?.VITE_NODE_ENV==="production" ? (
            <Sentry.ErrorBoundary fallback={FallbackComponent}>
              <App />
            </Sentry.ErrorBoundary>
          ):<App/>}
        
        </BrowserRouter>
      </Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </StrictMode>
);
