import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { UserManagementService } from "../../services/focal-scope-services";

const useGetCompanyList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  
) => {
  const getCompanyList = useQuery({
    queryKey: [
      "getCompanyList",
      
    ],
    queryFn: async () => {
      return await handleRequest(
        UserManagementService.getApiAppUserManagementCompanyList(
         
        )
      );
    },
  });

  return getCompanyList;
};

export default useGetCompanyList;
