import { Grid, Chip } from "@mui/material";

type DetailsLabelProps = {
  label: string;
  value: string;
  smWidth?: number;
  chip?: boolean;
  sx?: any;
};

export default function DetailLabel({
  label,
  value,
  smWidth,
  chip,
  sx
}: DetailsLabelProps) {
  return (
    <Grid item xs={12} sm={smWidth ? smWidth : 6}>
      <div className="pb-2 ">
        <span style={{ fontWeight: "bold" ,wordBreak:"break-all"}}>{label}: </span>
        {chip ? (
          <Chip label={value} variant="outlined" />
        ) : (
            <span style={{
              ...sx,
              // wordBreak: 'break-all', // Allow breaking words to prevent overflow
              overflowWrap: 'break-word', // Allow breaking words to prevent overflow
              // display: 'inline-block'
            }} >{value}</span>
        )}
      </div>
    </Grid>
  );
}
