import { lazy } from "react";
import MinimalLayout from "../layout/MinimalLayout";
import Loadable from "../ui-component/Loadable";
// import Callback from "../views/utilities/auth/Callback";

const Login = Loadable(lazy(() => import("../views/utilities/auth/Login")));

const Register = Loadable(
  lazy(() => import("../views/utilities/auth/Register"))
);
const Callback = Loadable(
  lazy(async () => await import("../views/utilities/auth/Callback"))
);

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/callback",
      element: <Callback />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    { path: "/register", element: <Register /> },
  ],
};

export default AuthenticationRoutes;
