import { Chip, IconButton, Paper, Skeleton, TableContainer, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { UserCheck } from "lucide-react";
import SearchIcon from "@mui/icons-material/Search";
import CustomTable from '../../../ui-component/customComponents/TableContainer';
import Header from '../../common/pageHeader/Header';
import AddIcon from "@mui/icons-material/Add";
import AddBlockUser from './AddBlockUser';
import useFocalScopeCoreApi from '../../../hooks/useFocalScopeAdminApi';
import { Row } from 'react-table';
import { useGetBlockUserList ,useUnblockUser} from '../../../hooks/appSetting';
import TableLoader from '../../common/loader/TableLoader';
import CustomPagination from '../../../ui-component/customComponents/CsPagination';
import moment from 'moment';
import CsConfirmModal from '../../../ui-component/customComponents/CsConfirmModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import Button from '../../../themes/overrides/Button';

interface IColumn {
  Header: string | (() => JSX.Element);
  accessor?: string;
  Cell?: ({ row }: { row: Row<any> }) => JSX.Element;
  hidden?: boolean;
}
const BlockUserList = ():React.ReactElement => {

  const [page, setPage] = React.useState(0);
  const { handleRequest, isLoading } = useFocalScopeCoreApi();
    const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
    const [sortBy, setSortBy] = useState<string>("");
    const [skipCount, setSkipCount] = React.useState<number>(0);
    const [maxResultCount, setMaxResultCount] = React.useState<number>(10);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [unblockId, setUnblockId] = React.useState<string>("");
    const [isUnblockPopupOpen, setIsUnblockPopupOpen] =React.useState<boolean>(false);
    const [blockType, setBlockType] = useState<string>("");
    const userData = useSelector((state: RootState) => state.user);
    

    const handleOpenAddModal = (editMode: boolean) => {
       // Set the row data in state
        setIsAddModalOpen(true);
        
      };
      const { data } = useGetBlockUserList(
        handleRequest,
        sortBy,
        skipCount,
        maxResultCount,
        searchKeyword,
       
      );
     
    const handleCloseModal = () => setIsAddModalOpen(false);
    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ): void => {
      setPage(newPage);
      setSkipCount(newPage * maxResultCount);
    };
  
    const putInTableFirstPage = () => {
      setPage(0);
      setSkipCount(0);
    };
  
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      setMaxResultCount(+event.target.value);
      putInTableFirstPage();
    };
   
    
    const { mutate: mutateUnblockUser } = useUnblockUser(handleRequest);
    const handleUnblockConfirmation = () => {
      mutateUnblockUser(unblockId, {
        onSuccess: () => {
          // Check if the current page has any data left after the unblock operation
          const remainingData = data?.data?.items?.filter((item) => item.id !== unblockId);
          if (remainingData?.length === 0) {
            // If the current page is empty, navigate to the previous page or the first page
            if (page > 0) {
              setPage(page - 1);
              setSkipCount((page - 1) * maxResultCount);
            } else {
              setSkipCount(0);
            }
          }
          setIsUnblockPopupOpen(false);
          setUnblockId("");
        },
      });
    };
    const columns: IColumn[] = React.useMemo(()=>{
      const filteredColumns = [ 
        {
          Header: "S.No",
          Cell: ({ row }: { row: Row }) => {
            return <>{skipCount + row.index + 1}</>;
          },
        },
        {
          Header: "Email/Domain",
          accessor: "blockValue",
          Cell: ({ value }: { value: string }) => (
            <Box component="span" sx={{ fontWeight: 700 }}>
              {value}
            </Box>
          ),
        },
        { Header: "Blocked On", accessor: "blockDate",
        Cell: ({ row }: any) => {
          const date = moment(row?.original?.blockDate);
          const formattedTime = date.format("HH:mm:ss");
          const formattedDate = date.format("DD-MM-YYYY");
          return (
            <div>
              <span>{formattedDate}</span>
              &nbsp; &nbsp;
              <span>{formattedTime}</span>
            </div>
          );
        },
         },
        {
          Header: "Type",
          accessor: "blockType",
          Cell: ({ value }: { value: string }) => (
            <Chip
              label={value}
              color={value === "Pending" ? "warning" : "success"}
            />
          ),
        },
        userData?.permission &&
        userData?.permission["BlockEndUser.Edit"]
        ?{
          Header:"Action",
          accessor: "Action",
          Cell: ({ row }: any) => {
            
            return (
              <div>
               
                  <Tooltip title="Unblock">
                    <IconButton
                      size="medium"
                      sx={{ color: "#408140" }}
                    aria-label="unblock"
                    onClick={() => handleBlockPopupOpen(row?.original?.id, row?.original?.blockType)}
                    >
                      <UserCheck size={16} />
                    </IconButton>
                  </Tooltip>
                
                   
              </div>
            )
          },

        }
        : null,
        
      ];

      return filteredColumns.filter((column) => column!== null) as IColumn[];
     }, [skipCount,userData]);
     const handleSearchItem = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchKeyword(e.target.value);
      putInTableFirstPage();
    };
    const handleBlockPopupClose = () => {
      setIsUnblockPopupOpen(false);
      setUnblockId("");
    };
    const handleBlockPopupOpen = (id: string, blockType: string) => {
      setIsUnblockPopupOpen(true);
      setUnblockId(id);
      setBlockType(blockType); // Add this line to store the blockType in state
    };
  return (
    <Box>
    <Header title="Blocked End-users"  
    showButton={userData?.permission["BlockEndUser.BlockDomain"] || userData?.permission["BlockEndUser.BlockEmail"]}
    buttonName="Blocked End-users"
    buttonIconComponent={<AddIcon />} 
   clickAction={()=> handleOpenAddModal(false) }/>

     <Box display="flex" justifyContent="end" mb={2}>
     <TextField
          InputProps={{
            startAdornment: <SearchIcon sx={{ fontSize: "22px" }} />,
          }}
          placeholder="Search Blocked Email/Domain"
          onChange={handleSearchItem}
          value={searchKeyword}
          size="small"
          sx={{width: "250px"}}
        />
        </Box>
   
   <AddBlockUser
          open={isAddModalOpen}
          onClose={handleCloseModal}
          
        />
        <Paper>
        <TableContainer>
          {isLoading ? (
            <TableLoader rowNumber={5} colsNumber={7} />
          ) : (
            <>
              {data?.data?.items && data?.data?.items.length > 0 ? (
                <CustomTable columns={columns} data={data?.data?.items} />
              ) : (
                <Typography sx={{ textAlign: "center", margin: "5rem" }}>
                    No Data Available
                  </Typography>
              )}
            </>
          )}
        </TableContainer>
        {isLoading ? (
          <Box p={2} display="flex" justifyContent="end">
            <Skeleton width={200} animation="wave" variant="text" />
          </Box>
        ) : (
          data?.data?.items &&
          data?.data?.items?.length > 0 && (
            <CustomPagination
              component="div"
              count={data?.data?.totalCount ?? 0}
              page={page}
              onPageChange={(
                event: React.MouseEvent<HTMLButtonElement> | null,
                newPage: number
              ) => handleChangePage(event, newPage)} // The handleChangePage function is called when the user changes the page.
              rowPerPage={maxResultCount}
              onRowsPerPageChange={handleChangeRowsPerPage} // handleChangeRowsPerPage is called when the user changes the number of rows per page[10,15,20].
              rowPerPageOption={[5, 10, 15, 20]}
            />
          )
        )}
      </Paper>
      <CsConfirmModal
  open={isUnblockPopupOpen}
  onClose={handleBlockPopupClose}
  onConfirm={handleUnblockConfirmation}
  title="Unblock Confirmation"
  description={
    blockType === "Customer"
      ? "Are you sure you want to unblock End-user?"
      : "Are you sure you want to unblock this domain?"
  }
  action={"Unblock"}
/>
      {/* <button onClick={()=> {throw new Error("Test Error  from admin dashboard")}
      }>Test Sentry Error</button> */}
   </Box>
  )
}

export default BlockUserList