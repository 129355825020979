import { useDispatch, useSelector } from "react-redux";

import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

// routing
import Routes from "./routes";

// defaultTheme

// project imports
import NavigationScroll from "./layout/NavigationScroll";

// ==============================|| APP ||============================== //
type RootState = {
  customization: any;
};

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { selectToken, setCredentials } from "./views/utilities/auth/authSlice";

// defaultTheme
import theme from "./themes";

// project imports
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFocalScopeCoreApi from "./hooks/useFocalScopeAdminApi";
import { AbpApplicationConfigurationService } from "./services/focal-scope-services";
import AuthService from "./services/focal-scope-services/authService";
import { AppDispatch } from "./store/reducer";
import { setUserRole } from "./views/utilities/auth/userRoleSlice";

// import { MeService } from "./services/variable-product-services";

// ==============================|| APP ||============================== //

const App = (): React.ReactElement => {
  const location = useLocation();
  const history = useNavigate();
  const customization = useSelector((state: any) => state.customization);
  const { handleRequest } = useFocalScopeCoreApi();
  const token = useSelector(selectToken);
  const user = useSelector((state: any) => state.user?.userRole);
  const [loading, isLoading] = useState<boolean>(true);

  // const gaTrackingID = window.__RUNTIME_CONFIG__.VITE_GA_TRACKING_CODE
  // React.useEffect(() => {
  //   if (gaTrackingID) {
  //     ReactGA.initialize(gaTrackingID, {
  //       gaOptions: {
  //         debug_mode: true,
  //       },
  //       gtagOptions: {
  //         debug_mode: true,
  //       },
  //     })
  //     ReactGA.send({ hitType: "pageview", page: location.pathname })
  //   }
  // }, [location])

  /* Login */
  const dispatch: AppDispatch = useDispatch();
  const authService = new AuthService();

  useEffect(() => {
    if (authService.isAuthenticated()) {
      authService
        .getUser()
        .then((user) => {
          if (user != null) {
            dispatch(
              setCredentials({
                isAuthenticated: authService.isAuthenticated(),
                token: authService.getToken(),
                user: user.profile,
              })
            );
          }
        })
        .catch((err) => {
          if (err) authService.signoutRedirectCallback();
       
        });
    } else {
      if (location.pathname !== "/callback") {
        // * If the user is not authenticated and route doesn't match the callback but the route matches the register route then extract the roleId and companyId from the url and then register the user.
        if (location.pathname === "/register") {
          const searchParams = new URLSearchParams(window.location.search);

          const newURL = `/register${
            searchParams.toString() ? `?${searchParams.toString()}` : ""
          }`; // Preventing the loss of the search parameters

          history(newURL);
          return; // Prevent the application from redirecting thr user to the login page again.
        }
        if (location.pathname.startsWith("/webviews")) {
          return;
        }
        history("/login");
      }
    }
  }, []);

  useEffect(() => {
    if (token?.length > 0) {
      void handleRequest(
        AbpApplicationConfigurationService.getApiAbpApplicationConfiguration()
      ).then((values: any) => {
        dispatch(
          setUserRole({
            userRole: values,
            permission: values?.auth?.grantedPolicies,
          })
        );
      });
    }
  }, [token]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
      <ToastContainer />
    </StyledEngineProvider>
  );
};

export default App;
