import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { BlockListService } from "../../../services/focal-scope-services";

const useGetBlockUserList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  sorting?: string,
  skipCount?: number,
  maxResultCount?: number,
  searchKeyword?: string,
) => {
  const getBlockListData = useQuery({
    queryKey: [
      "getAllBlockUser",
      sorting,
      skipCount,
      maxResultCount,
      searchKeyword,
    ],
    queryFn: async () => {
      const result = await handleRequest(
        BlockListService.getApiAppBlockListBlockList(
          sorting,
          skipCount,
          maxResultCount,
          searchKeyword
        )
      );
      // Add a log to check fetched data
      return result;
    },
  });

   // Check the data from useQuery
  return getBlockListData;
};

export default useGetBlockUserList;
