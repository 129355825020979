import { borderBottom, fontSize, fontWeight } from "@mui/system";

export default function componentStyleOverrides(theme: any) {
  const bgColor = theme.colors?.grey50;
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: "4px",
          fontSize: "1.2rem",
        },
        contained: {
          color: theme.buttonText, // Text color for contained buttons
        },
        outlined: {
          background: theme.layout,
          color: theme.buttonText,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
          "&.MuiCardContent-root": {
            padding: "1rem 1rem .5rem 1rem",
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: theme.darkTextPrimary,
            backgroundColor: theme.layout,
            "&:hover": {
              backgroundColor: theme.layout,
              color: theme.darkTextPrimary,
              "& .MuiListItemIcon-root": {
                color: theme.darkTextPrimary,
              },
            },

            "& .MuiListItemIcon-root": {
              color: theme.darkTextPrimary,
            },
          },
          "&:hover": {
            backgroundColor: theme.menuHoverBack,
            color: theme.darkTextPrimary,
            "& .MuiListItemIcon-root": {
              color: theme.darkTextPrimary,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: "32px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // position: "static",
          fontSize: "1.4rem",
          transform: "none",
          marginBottom: "5px",
          color: "#333",
          // "&.Mui-disabled": {
          //   "& svg": { color: theme.palette.text.disabled },
          // },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "white",
          "& .MuiOutlinedInput-notchedOutline": {
            // borderColor: alpha(theme.palette.grey[500], 0.32),
            // top: 0,
            // legend: {
            //   display: "none",
            // },
          },

          // "&.Mui-disabled": {
          //   "& .MuiOutlinedInput-notchedOutline": {
          //     borderColor: theme.palette.action.disabledBackground,
          //   },
          // },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {},
      },
    },
    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       color: theme.textDark,
    //       "&::placeholder": {
    //         color: theme.darkTextSecondary,
    //         fontSize: "1.2rem",
    //       },
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       background: bgColor,
    //       borderRadius: `${theme?.customization?.borderRadius}px`,
    //       "& .MuiOutlinedInput-notchedOutline": {
    //         borderColor: theme.colors?.grey400,
    //       },
    //       "&:hover $notchedOutline": {
    //         borderColor: theme.colors?.primaryLight,
    //       },
    //       "&.MuiInputBase-multiline": {
    //         padding: 1,
    //       },
    //     },
    //     input: {
    //       fontWeight: 500,
    //       background: bgColor,
    //       padding: "10px 14px",
    //       borderRadius: `${theme?.customization?.borderRadius}px`,
    //       "&.MuiInputBase-inputSizeSmall": {
    //         padding: "10px 14px",
    //         "&.MuiInputBase-inputAdornedStart": {
    //           paddingLeft: 0,
    //         },
    //       },
    //     },
    //     inputAdornedStart: {
    //       paddingLeft: 4,
    //     },
    //     notchedOutline: {
    //       borderRadius: `${theme?.customization?.borderRadius}px`,
    //     },
    //   },
    // },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.colors?.grey300,
          },
        },
        mark: {
          backgroundColor: theme.paper,
          width: "4px",
        },
        valueLabel: {
          color: theme?.colors?.primaryLight,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.headline}`,
          opacity: 1,
          // marginTop: ".4rem",
          // marginBottom: "1rem",
          // "&.MuiDivider-root": {},
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.grey100,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1.3rem",
          height: "40px",
          whiteSpace:'noWrap'
        },
        body: {
          borderBottom: "1px solid rgba(241, 243, 244, 1)",
          padding: "0 16px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem",
          marginInline: 0,
          marginTop: 0,
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        wrapperInner: {
          display: "flex",
          flexDirection: "column",
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          // padding:'1rem'
        },
        content: {
          padding: "1rem",
        },
        label: {
          fontSize: "1.5rem",
          "> div": {
            display: "flex",
            alignItems: "center",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem",
          lineHeight: "1.6rem",
          fontWeight: 400,
          borderRadius: "4px",
          padding: "2px 5px 2px ",
          height: "22px",
          gap: "5px",
        },
        label: {
          // fontSize:'1.8rem'
          padding: 0,
        },
        icon: {
          marginInline: 0,
          width: "1.4rem",
          height: "1.4rem",
        },
        colorSecondary: {
          background: "#ebeff3",
          color: "#161616",
        },
        colorSuccess: {
          background: "rgb(224, 245, 241)",
          color: "rgb(0, 121, 91)",
        },
        colorError: {
          background: "rgb(255, 236, 240)",
          color: "rgb(215, 45, 48)",
        },
        colorInfo: {
          background: "rgb(229, 242, 253)",
          color: "rgb(44, 92, 197)",
        },
        colorWarning: {
          background: "rgb(254, 241, 225)",
          color: "rgb(232, 111, 37)",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700,
          // backgroundColor: theme.palette.grey[800],
          fontSize: "1.2rem",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.MuiTab-textColorPrimary": {
            color: theme.bodyText,
          },
          "&.Mui-selected": {
            color: theme.buttonText,
          },
          fontSize: "1.1rem",
          fontWeight: 400,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "&.MuiTabs-indicator": {
            color: "red",
          },
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontSize: "1.4rem",
        },
        selectLabel: {
          fontSize: "1.4rem",
        },
        displayedRows: {
          fontSize: "1.4rem",
        },
        actions: {
          fontSize: "1.4rem",
        },
      },
    },
  };
}
