import { useCallback, useEffect, useState } from "react";
import { OpenAPI, type ApiError } from "../services/focal-scope-services";
import { selectToken } from "../views/utilities/auth/authSlice";
import { toast } from "react-toastify";
import AuthService from "../services/focal-scope-services/authService";
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom

interface Type {
  dismissError: () => void;
  error: ApiError | undefined;
  isLoading: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
}

export const useFocalScopeCoreApi = (): Type => {
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const navigate = useNavigate();  // Initialize useNavigate
  const authService = new AuthService();

  OpenAPI.BASE = window.__RUNTIME_CONFIG__?.VITE_BASE_URL ?? "";
  OpenAPI.TOKEN = selectToken()?.toString();

  const handleRequest = useCallback(async function <T>(request: Promise<T>) {
    setIsloading(true);
    try {
      const response = await request;
      setError(undefined);
      return response;
    } catch (exception: any) {
      Sentry.captureException(exception);
      toast.error(exception?.body?.error?.message);
      if (exception?.status === 401 || exception?.status === 409) {
        authService.logout();
      // } else if (exception?.status === 403) {
      //   toast.error("You have no permission");
      //   navigate("/dashboard");  // Navigate to the dashboard
      } else {
        setError(exception);
        if (exception?.body?.error?.validationErrors !== null) {
          toast.error(exception?.body?.error?.validationErrors);
        } else if (exception.message !== null) {
          throw new Error(exception.message);
        } else {
          toast.error("Unable to fetch api");
        }
      }
    } finally {
      setIsloading(false);
    }
  }, [error, navigate, authService]);

  const dismissError = (): void => {
    setError(undefined);
  };

  return { dismissError, error, isLoading, handleRequest };
};

export default useFocalScopeCoreApi;
