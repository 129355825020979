import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// material-ui
import Person2Icon from "@mui/icons-material/Person2";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AuthService from "../../../../services/focal-scope-services/authService";
// third-party

// project imports

// const User1:string = require('assets/images/users/user-round.svg').default;
// assets
import ApplicationConfigurationService from "../../../../hooks/configurationService/ApplicationConfigurationService";
import useProfilePicture from "../../../../hooks/profile/get/useGetProfilePicture";
import useFocalScopeCoreApi from "../../../../hooks/useFocalScopeAdminApi";
// ==============================|| PROFILE MENU ||============================== //
type RootState = {
  customization: any;
};
const ProfileSection = () => {
  const theme = useTheme<any>();
  const customization = useSelector((state: RootState) => state.customization);
  const navigate = useNavigate();
  const authService = new AuthService();
  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  // const [open, setOpen] = useState(false);
  // const [greeting, setGreeting] = useState<string>("");
  const anchorRef = useRef<any>(null);
  const handleLogout = async (): Promise<void> => {
    authService.logout();
  };

  const { handleRequest } = useFocalScopeCoreApi();
  const { data } = ApplicationConfigurationService(handleRequest);

  const user = data?.currentUser?.name?.toUpperCase();

  const { data: getProfile } = useProfilePicture(
    handleRequest,
    data?.currentUser?.id || ""
  );

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  // useEffect(() => {
  //   updateGreeting();
  // }, []);
  const handleProfileClick = () => {
    navigate("/profile");
  };
  // const updateGreeting = () => {
  //   const currentHour = new Date().getHours();
  //   let newGreeting = "";

  //   if (currentHour >= 5 && currentHour < 12) {
  //     newGreeting = "Good Morning, ";
  //   } else if (currentHour >= 12 && currentHour < 17) {
  //     newGreeting = "Good Afternoon, ";
  //   } else if (currentHour >= 17 && currentHour < 20) {
  //     newGreeting = "Good Evening, ";
  //   } else {
  //     newGreeting = "Good Night, ";
  //   }
  //   setGreeting(newGreeting);
  // };

  const handleListItemClick = (event: any, index: any, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  // const prevOpen = useRef(open);
  // useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);
  const [open, setOpen] = useState<any>(null);
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  // const handleClose = () => {
  //   setOpen(null);
  // };
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          sx={{ width: "42px", height: "42px", border: "3px solid #0a67b4" }}
          src={`data:image/png;base64,${getProfile?.data}`}
        >
          {!getProfile?.data && (
            <Person2Icon
              sx={{ width: "30px", height: "30px", color: "white" }}
            />
          )}
        </Avatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 200,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="h6" noWrap>
            {"Hello 👋 , "}
            {user}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem selected={selectedIndex === 0} onClick={handleProfileClick}>
            <Typography variant="body1">Account Settings</Typography>
          </MenuItem>
          <Divider sx={{ borderStyle: "dashed" }} />
          <MenuItem selected={selectedIndex === 4} onClick={handleLogout}>
            <Typography variant="body1">Logout</Typography>
          </MenuItem>
        </Stack>
      </Popover>
    </>
  );
};

export default ProfileSection;
